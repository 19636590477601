import { Header } from '@benefits/components';
import { LogoIfoodBeneficios, LogoIfoodBeneficiosShort } from '@benefits/crafts';
import { PropsWithChildren } from 'react';

import { Drawer } from '../../../Drawer/Drawer';
import { BaseLayout } from '../../BaseLayout';
import type { BaseLayoutProps } from '../../BaseLayout';
import { Container, DashboardWrapper, FlexContainer } from './DashboardLayout.styled';

type DashboardLayoutProps = {
  configSteps?: React.ReactNode;
};

export const DashboardLayout: React.FC<PropsWithChildren<BaseLayoutProps> & DashboardLayoutProps> = ({
  children,
  logo = <LogoIfoodBeneficios.IfoodBeneficiosSVG />,
  logoMobile = <LogoIfoodBeneficiosShort.IfoodBeneficiosShortSVG />,
  configSteps,
  ...baseProps
}) => (
  <BaseLayout {...baseProps}>
    <Header headerLogo={logo} headerMobileLogo={logoMobile} />
    <Drawer />
    {configSteps && configSteps}
    <FlexContainer>
      <Container component="main" maxWidth="xl">
        <DashboardWrapper>{children}</DashboardWrapper>
      </Container>
    </FlexContainer>
  </BaseLayout>
);
