import { variables } from '@benefits/environment';

type CheckEnvironmentFn = () => boolean;

type NodeEnvironment = {
  environment: string;
  isDevelopment: CheckEnvironmentFn;
  isProduction: CheckEnvironmentFn;
};

type AppEnvironment = {
  environment: string;
  isLocal: CheckEnvironmentFn;
  isSandbox: CheckEnvironmentFn;
  isProduction: CheckEnvironmentFn;
};

if (!process.env.NODE_ENV) {
  throw new Error('NODE_ENV is missing');
}

export const node: NodeEnvironment = {
  environment: process.env.NODE_ENV,
  isDevelopment() {
    return process.env.NODE_ENV === 'development';
  },
  isProduction() {
    return process.env.NODE_ENV === 'production';
  },
};

export const app: AppEnvironment = {
  environment: variables?.NEXT_PUBLIC_ENV || 'local',
  isLocal() {
    return !this.environment || this.environment === 'local';
  },
  isSandbox() {
    return this.environment === 'sandbox';
  },
  isProduction() {
    return this.environment === 'production';
  },
};

// TODO remove direct usage to theese functions
export const isProduction = node.isProduction;
export const isDevelopment = node.isDevelopment;
