import { HttpMethods, HttpStatusCodes, UnexpectedError, makeRequest } from '@benefits/http-client';

import { BASE_URL, ENDPOINTS } from './endpoints';

type GetFeatureTogglesResponseBody = {
  toggles: { [key: string]: boolean };
  lists: { [key: string]: string[] };
};

export const getFeatureToggles = async () => {
  const response = await makeRequest<GetFeatureTogglesResponseBody>({
    baseURL: BASE_URL,
    method: HttpMethods.GET,
    url: ENDPOINTS.features.get,
  });

  switch (response.status) {
    case HttpStatusCodes.OK:
      return response.body;

    default:
      throw new UnexpectedError(response?.error);
  }
};
