import { Container } from '@benefits/components';
import { PropsWithChildren } from 'react';

import { PageHead, PageHeadProps } from '../../PageHead/PageHead';

export const ContainerLayout: React.FC<PropsWithChildren<PageHeadProps>> = ({ children, ...props }) => {
  return (
    <>
      <PageHead {...props} />
      <Container>{children}</Container>
    </>
  );
};
