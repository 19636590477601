import { EventParamsType, EventsDictionary } from '../types';

export enum IfoodCardEvents {
  IfoodcardSubHome_view = 'ifoodcard:subHome_view',
  IfoodcardSubHome_Click = 'ifoodcard:subHome_Click',
  IfoodcardBuying_view = 'ifoodcard:buying_view',
  IfoodcardBuyingSelectingValues_click = 'ifoodcard:buyingSelectingValues_click',
  IfoodcardBuyingEditcart_click = 'ifoodcard:buyingEditcart_click',
  IfoodcardBuyingContinue_click = 'ifoodcard:buyingContinue_click',
  IfoodcardBuyingBack_click = 'ifoodcard:buyingBack_click',
  IfoodcardBuyingSystemError_view = 'ifoodcard:buyingSystemError_view',
  IfoodcardMyOrders_view = 'ifoodcard:myOrders_view',
  IfoodcardMyOrdersWaitingPayment_click = 'ifoodcard:myOrdersWaitingPayment_click',
  IfoodcardMyOrdersCardsAvailable_click = 'ifoodcard:myOrdersCardsAvailable_click',
  IfoodcardBreadcrumbs_click = 'ifoodcard:breadcrumbs_click',
  IfoodcardConfirmingRequest_view = 'ifoodcard:confirmingRequest_view',
  IfoodcardConfirmingRequest_click = 'ifoodcard:confirmingRequest_click',
  IfoodcardCompleteOrder_click = 'ifoodcard:completeOrder_click',
  IfoodcardSuccessRequest_view = 'ifoodcard:successRequest_view',
  IfoodcardSuccessRequest_click = 'ifoodcard:successRequest_click',
}

export type IfoodCardEventsType = typeof IfoodCardEvents;

export type IfoodCardEventType = keyof IfoodCardEventsType;

export type IfoodCardEventTypeCollection = IfoodCardEventType[];

const CardEventKeys = Object.keys(IfoodCardEvents) as IfoodCardEventTypeCollection;

export const ifoodCard: EventsDictionary = CardEventKeys.reduce((collection, eventKey) => {
  const eventName = IfoodCardEvents[eventKey];

  return {
    ...collection,
    [eventName]: <IfoodCardEventParams>(params: EventParamsType<IfoodCardEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
