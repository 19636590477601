import { BreakpointsType } from './breakpoints.types';

export const breakpoints: BreakpointsType = {
  mobile: {
    s: '320px',
    m: '375px',
    l: '414px',
  },
  tablet: '768px',
  desktop: {
    s: '1024px',
    m: '1280px',
    l: '1440px',
    xl: '1920px',
  },
};
