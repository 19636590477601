import { SidebarLayout as SidebarLayoutDS } from '@ifood/fits-components';
import styled, { css } from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: ${({ theme }) => css`calc(100vh - ${theme.component.navBar.root.minHeight})`};
`;

export const SidebarLayout = styled(SidebarLayoutDS)`
  padding-inline-start: max(32px, env(safe-area-inset-left));
  padding-inline-end: max(32px, env(safe-area-inset-right));
  min-width: 1432px;
  main {
    margin-top: 56px;
    padding: 0%;
  }
  aside {
    min-height: 100vh;
  }
`;

export const DrawerContainer = styled.div`
  margin-top: ${({ theme }) =>
    css`
      ${theme.component.navBar.root.minHeight}
    `};
`;
