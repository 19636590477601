import { useNews } from '@benefits/common-services';
import { BaseLayout, Button, Heading, NewsLottie } from '@benefits/components';
import { Icons } from '@benefits/crafts';
import { Dashboard } from '@benefits/router-services';
import Router from 'next/router';
import { useEffect, useState } from 'react';

import { NewsComponentProps } from '../News.types';
import { NewsDialog } from '../NewsDialog/News.dialog';
import {
  Badge,
  ButtonGroup,
  DescriptionContent,
  DescriptionWrapper,
  IllustrationContainer,
  NewsLetter,
  Waves,
} from './NewsLight.styles';

const NewsLight: React.FC<NewsComponentProps> = ({ onPageView, onInterest, onSeeLater, config }) => {
  const [isOpen, setDialog] = useState(false);
  const { read } = useNews();

  const {
    newsTheme,
    title,
    shortTitle,
    description,
    seeLaterButtonTitle,
    howToKnowButtonTitle,
    dialog,
    route,
    newRoute,
    illustration,
    lottie,
    footer,
  } = config;

  const toggle = () => setDialog((prevState) => !prevState);

  const seeLater = () => {
    read(newRoute);
    onSeeLater?.({ newsTheme });
    Router.push({ pathname: Dashboard.DASHBOARD });
  };

  const goTo = () => {
    read(newRoute);
    onInterest?.({ newsTheme });
    const isLegacyRoute = route?.includes('/v3');
    isLegacyRoute ? location.assign(route) : Router.push(route);
  };

  useEffect(() => {
    onPageView?.({ newsTheme });
  }, []);

  return (
    <BaseLayout title={shortTitle}>
      <NewsLetter>
        <DescriptionWrapper>
          <DescriptionContent>
            <div>
              <Badge aria-label="Novidade">
                <Icons.ShineFilled iconColor="#E7A74E" />
                Novidade
              </Badge>
            </div>
            <Heading weight="bold">{title}</Heading>
            <div>{description}</div>

            <ButtonGroup>
              <Button shapeStyle="pill" variant="tertiary" onClick={seeLater}>
                {seeLaterButtonTitle}
              </Button>
              <Button shapeStyle="pill" onClick={dialog ? toggle : goTo}>
                {howToKnowButtonTitle}
              </Button>
            </ButtonGroup>
          </DescriptionContent>
          {footer}
        </DescriptionWrapper>

        <Waves />

        <IllustrationContainer>{lottie ? <NewsLottie lottie={lottie} /> : illustration?.img}</IllustrationContainer>
      </NewsLetter>

      {dialog && (
        <NewsDialog
          actionTitleButton={dialog.actionText}
          banner={title}
          callback={goTo}
          closeToHomeTitleButton={dialog.closeText}
          isOpen={isOpen}
          newsTheme={newsTheme}
          seeLater={seeLater}
          title={dialog.title}
          toggle={toggle}
          videoId={dialog.videoId}
        />
      )}
    </BaseLayout>
  );
};

export { NewsLight };
