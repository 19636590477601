import { HttpMethods, HttpStatusCodes } from '../constants';
import { httpClient } from '../httpClient/httpClient';

export type RequestParams = {
  url: string;
  body?: any;
  baseURL?: string;
  params?: any;
  method: string | HttpMethods;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
};

export type Response<T> = {
  body: T;
  status: HttpStatusCodes;
  error?: any | Error;
};

export const request = async <T>({
  baseURL,
  url,
  body,
  params,
  method,
  onUploadProgress,
}: RequestParams): Promise<Response<T>> => {
  try {
    const response = await httpClient({
      baseURL,
      url,
      params,
      data: body,
      method,
      onUploadProgress,
    } as any);

    return {
      body: response?.data,
      status: response?.status,
    };
  } catch (error: any) {
    return {
      status: error?.response.status,
      body: error?.response?.data,
      error,
    };
  }
};
