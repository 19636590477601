import { useAccessControl, useLocalStorage, useSessionStorage } from '@benefits/common-services';
import { PropsWithChildren, createContext } from 'react';

import { UserContextType } from './UserStore.types';

const UserContext = createContext<UserContextType | null>(null);
UserContext.displayName = 'UserContext';

const UserStore: React.FC<PropsWithChildren<unknown>> = (props) => {
  const [user, saveUser, removeUser] = useLocalStorage('_ifood_companies_user');
  const [, , removeSession] = useSessionStorage('_ife_feature_flags');
  const [, , removeProducts] = useSessionStorage('_ife_products');
  const [, , removePermissions] = useSessionStorage('_ife_permissions');
  const { acl } = useAccessControl();

  const onClean = () => {
    // clean user
    removeUser();
    // clean feature flags
    removeSession();
    // clean products
    removeProducts();
    // clean permissions
    removePermissions();
    // clean acl
    acl?.clear();
  };

  const setUser = (value: any) => saveUser(JSON.stringify(value));
  const getUser = user ? JSON.parse(user as string) : null;

  return (
    <UserContext.Provider
      value={{
        state: {
          user: getUser,
        },
        dispatch: {
          user: setUser,
          clear: onClean,
        },
      }}
      {...props}
    />
  );
};

export { UserContext, UserStore };
