import { ColorsType } from './colors.types';

export const colors: ColorsType = {
  white: '#fff',
  black: '#000',
  brand: {
    ifood: '#EA1D2C',
    lightest: '#FCEBEA',
    light: '#FFDEDC',
    dark: '#BB111E',
  },
  gray: {
    '100': '#FFFFFF',
    '200': '#FCFCFC',
    '300': '#F9F9F9',
    '400': '#F2F2F2',
    '500': '#DCDCDC',
    '600': '#A6A6A6',
    '700': '#717171',
    '800': '#3E3E3E',
    '900': '#1A1A1A',
  },
  success: {
    '100': '#E7F4EF',
    '200': '#A3D7C2',
    '300': '#49A782',
    '400': '#377E62',
    '500': '#317057',
  },
  attention: {
    '100': '#FEF6E9',
    '200': '#F9DA9F',
    '300': '#F6C567',
    '400': '#F4B742',
    '500': '#EDA10E',
  },
  critical: {
    '100': '#FCE5E2',
    '200': '#F9C4BF',
    '300': '#EF6153',
    '400': '#EA301D',
    '500': '#BB2111',
  },
  informative: {
    '100': '#EAEFF9',
    '200': '#9DB6EA',
    '300': '#5F89D3',
    '400': '#3569C4',
    '500': '#2D57A4',
  },
};
