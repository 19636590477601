import { Text } from '@ifood/fits-components';
import styled, { css } from 'styled-components';

const checkedStyle = css`
  &:after {
    transform: scale(0.5);
  }

  &:before {
    border: 1px solid #49a782;
  }
`;

export const Label = styled(Text)`
  position: relative;
  padding-left: 2.1875rem;
  cursor: pointer;
  display: inline-block;
  height: 1.5625rem;
  line-height: 1.5625rem;
  transition: 0.3s ease;
  user-select: none;
  color: #1a1a1a;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0rem;
    margin: 0.25rem;
    width: 1rem;
    height: 1rem;
    z-index: 0;
  }

  &:before {
    border: 0.0625rem solid #3e3e3e;
    border-radius: 50%;
  }

  &:after {
    border: 0.0625rem solid #49a782;
    background-color: #49a782;
    transition: 0.3s ease;
    transform: scale(0);
    border-radius: 50%;
  }
`;

export const Input = styled.input`
  position: absolute;
  visibility: hidden;
  &:checked {
    & + span {
      ${checkedStyle}
    }
  }
`;
