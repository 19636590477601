type GetAuthorizationCodeType = () => {
  Authorization?: string;
};

export const getAuthorizationCode: GetAuthorizationCodeType = () => {
  const user = typeof window !== 'undefined' ? localStorage.getItem('_ifood_companies_user') : '';

  if (user) {
    const { accessToken } = JSON.parse(user);

    return { Authorization: `Bearer ${accessToken}` };
  }

  return {};
};
