import styled from 'styled-components';

import type { StepVariantType } from '../Stepper.types';

export const iconColors = {
  completed: '#377E62',
  current: '#EA1D2C',
  next: '#717171',
};

export const textColors = {
  completed: '#377E62',
  current: '#1A1A1A',
  next: '#717171',
};

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc;
  padding: 12px;
  border-radius: 2px;
  min-width: max-content;
`;

export const Description = styled.span<{ variant: StepVariantType }>`
  color: ${({ variant }) => textColors[variant]};
  margin: 0;
  font-weight: bold;
  padding-left: 8px;
`;

export const CurrentStepIcon = styled.div`
  position: absolute;
  right: -15px;
  bottom: -17px;
`;
