import { Icons } from '@benefits/crafts';

export const width = 592;

export const colors = {
  attention: {
    background: '#F9DA9F',
    color: '#f6c567',
  },
  critical: {
    background: '#F9C4BF',
    color: '#EF6153',
  },
  informative: {
    background: '#9DB6E4',
    color: '#5F89D3',
  },
  success: {
    background: '#A3D7C2',
    color: '#49A782',
  },
};

export const iconVariant = {
  attention: Icons.BellFilled,
  critical: Icons.CircleCrossFilled,
  success: Icons.CircleCheckmarkFilled,
  informative: Icons.CircleInfoFilled,
};
