import React, { PropsWithChildren } from 'react';

interface IErrorBoundaryProps {
  error?: Error;
}

const ErrorBoundary: React.FC<PropsWithChildren<IErrorBoundaryProps>> = ({ error = 'Microfront 404' }) => (
  <h1>ErrorBoundary - {JSON.stringify(error)}</h1>
);

export { ErrorBoundary };
