import { Badge as BadgeFits } from '@benefits/components';
import styled, { keyframes } from 'styled-components';
const isProduction = process.env.NODE_ENV === 'production';

export const Waves = styled.div`
  background: url(${isProduction ? '/new' : ''}/assets/images/waves/wave-black.svg);
  height: 100vh;
  width: 81px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 32px;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

export const NewsLetter = styled.main`
  display: flex;
`;

export const DescriptionWrapper = styled.section`
  height: 100vh;
  width: calc(50% - 81px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DescriptionContent = styled.article`
  display: grid;
  gap: 24px;
  width: 80%;

  h1 {
    font-size: 2rem;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const IllustrationContainer = styled.section`
  height: 100vh;
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;

  img,
  svg {
    width: 100%;
    height: 80%;
    max-height: 100%;
    animation: 1.5s ${fadeIn} ease-out;
  }
`;

export const Badge = styled(BadgeFits)`
  background: #fef8ec;
  height: auto;
  display: inline-flex;
  align-items: center;
  padding-right: 12px;
  gap: 2px;

  & > span {
    display: flex;
  }
`;

export const SubscriptionCourse = styled.div`
  margin-top: ${({ theme }) => theme.spacing.spacingXl};
  border: 1px rgba(61, 61, 61, 0.08) solid;
  width: 80%;
  padding: ${({ theme }) => theme.spacing.spacing2xs};
  border-left: 8px solid ${({ theme }) => theme.color.colorRoleInformative};
  display: flex;
`;
