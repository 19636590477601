import { featureToggleValidations } from '@benefits/common-utils';
import { WITH_CREDENTIALS } from '@benefits/features';
import axios, { AxiosResponse } from 'axios';

import { API_URL } from '../constants';
import { getAuthorizationCode } from './getAuthorizationCode';
import { redirectUnauthorized } from './redirectUnauthorized';

const responseNormalize = (response: AxiosResponse) => response;

const source = axios.CancelToken.source();

const httpClient = axios.create({
  cancelToken: source.token,
});

httpClient.interceptors.request.use(async (config) => {
  config.headers = {
    ...config.headers,
    ...getAuthorizationCode(),
  };
  const isServer = typeof window === 'undefined';

  if (!isServer) {
    const { isEnable } = featureToggleValidations();
    const withCredentialsEnabled = isEnable(WITH_CREDENTIALS);

    if (withCredentialsEnabled) config.withCredentials = Boolean(withCredentialsEnabled);

    config.headers = {
      ...config.headers,
      incognia_session_token: await window.IncogniaWebSdk.generateRequestToken(),
    };
  }

  return config;
});

httpClient.interceptors.response.use(responseNormalize, redirectUnauthorized);
httpClient.defaults.baseURL = API_URL;
httpClient.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
httpClient.defaults.timeout = 120000;

export { httpClient, source };
