import { RenderFederatedModule } from '@benefits/components';
import { NOTIFICATION_EXP, ToggleContext } from '@benefits/features';
import { Dashboard } from '@benefits/router-services';
import { useHeaderDispatcher } from '@benefits/tracking/src/events/header/useHeaderDispatcher';
import { useHelpDispatcher } from '@benefits/tracking/src/events/help/useHelperDispatcher';
import { NavBarProps } from '@ifood/fits-components';
import Router from 'next/router';
import { Fragment, useCallback, useContext } from 'react';

import { ButtonLogo, HeaderGroup, NavBar } from './Header.styles';

interface HeaderProps extends NavBarProps {
  headerLogo: React.ReactNode;
  headerMobileLogo?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ headerLogo, headerMobileLogo, maxWidth = 'xl' }) => {
  const { sendEvent } = useHelpDispatcher();
  const { sendEvent: sendEventHeader } = useHeaderDispatcher();

  const featureTogglesContent = useContext(ToggleContext);

  const isNotificationExpEnabled = featureTogglesContent?.isEnable(NOTIFICATION_EXP);

  const handleRedirectToDashboard = useCallback(() => {
    sendEventHeader('HeaderLogoClick');
    Router.push(Dashboard.DASHBOARD);
  }, [sendEventHeader]);

  return (
    <>
      <NavBar maxWidth={maxWidth} position="fixed">
        <HeaderGroup>
          <ButtonLogo hasMobileLogo={!!headerMobileLogo} onClick={handleRedirectToDashboard}>
            {headerLogo && <span className="desktopLogo">{headerLogo}</span>}
            {headerMobileLogo && <span className="mobileLogo">{headerMobileLogo}</span>}
          </ButtonLogo>

          <RenderFederatedModule
            component="header-switch"
            errorBoundary={() => <Fragment />}
            loadingComponent={() => <Fragment />}
            remote="license"
          />
        </HeaderGroup>

        <HeaderGroup>
          {isNotificationExpEnabled && (
            <RenderFederatedModule
              component="header-notification"
              errorBoundary={() => <Fragment />}
              loadingComponent={() => <Fragment />}
              remote="notification"
            />
          )}

          <RenderFederatedModule
            component="header-profile"
            errorBoundary={() => <Fragment />}
            loadingComponent={() => <Fragment />}
            remote="auth"
          />
        </HeaderGroup>
      </NavBar>
    </>
  );
};

export { Header };
