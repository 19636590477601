import { useEffect, useRef, useState } from 'react';
import { Observable } from 'windowed-observable';

type MessageType = Record<string, unknown> | string;
type ObservableType = Observable | null;

type OptionsType = {
  onReceive?: (data: MessageType) => void;
};

type UseMessageType = OptionsType & {
  key: string;
};

type UseMessageTypeReturn = {
  messages: MessageType[];
  publish: (message: MessageType) => void;
};

const useMessage = (key: string, options?: OptionsType): UseMessageTypeReturn => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const observableRef = useRef<ObservableType>(null);

  const messageInterceptor = (newMessage: MessageType) => {
    const totalEmployees = sessionStorage.getItem('totalEmployees');

    const messageWithTotalEmployees = {
      ...(typeof newMessage === 'object' ? newMessage : {}),
      colabRange: totalEmployees ? JSON.parse(totalEmployees) : '',
    };
    setMessages((currentMessages) => [messageWithTotalEmployees, ...currentMessages]);

    if (options?.onReceive) options?.onReceive?.(messageWithTotalEmployees);
  };

  const publish = (message: MessageType) => {
    observableRef.current?.publish(message);
  };

  useEffect(() => {
    const listener = new Observable(key);
    observableRef.current = listener;

    listener.subscribe(messageInterceptor);

    return () => {
      listener.unsubscribe(messageInterceptor);
    };
  }, []);

  return { messages, publish };
};

export { useMessage };
