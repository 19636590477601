import styled, { FlattenInterpolation, ThemeProps, css } from 'styled-components';

import { width } from '../Snackbar.config';
import { PositionType } from './Group.types';

export const SnackGroup = styled.section<{ position: PositionType }>`
  position: fixed;

  z-index: 9999999;

  display: grid;
  gap: 1rem;

  ${({ position }) => getPositionStyles(position)};
`;

const positionTop = css`
  top: 24px;
  left: calc(50% - ${width / 2}px);

  @media (max-width: 768px) {
    left: 24px;
  }
`;

const positionBottomRight = css`
  bottom: 24px;
  right: 24px;
`;

const positionTopLeft = css`
  top: 24px;
  left: 24px;
`;

const positionTopRight = css`
  top: 24px;
  right: 24px;
`;

const positionBottom = css`
  bottom: 24px;
  left: calc(50% - ${width / 2}px);

  @media (max-width: 768px) {
    left: 24px;
  }
`;

const positionBottomLeft = css`
  bottom: 24px;
  left: 24px;
`;

const positions = {
  top: positionTop,
  'top-left': positionTopLeft,
  'top-right': positionTopRight,
  bottom: positionBottom,
  'bottom-left': positionBottomLeft,
  'bottom-right': positionBottomRight,
};

export const getPositionStyles = (position: PositionType): FlattenInterpolation<ThemeProps<any>> => positions[position];
