export const NEWS_CONFIG = {};

export const NEWS_CONFIG_CARDS =
  NEWS_CONFIG &&
  Object.keys(NEWS_CONFIG).map((key) => {
    const {
      shortTitle,
      date,
      name,
      featureRollout,
      illustration: { short },
    } = NEWS_CONFIG[key as keyof typeof NEWS_CONFIG];

    return {
      key,
      shortTitle,
      date,
      name,
      featureRollout,
      illustration: { short },
    };
  });
