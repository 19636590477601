export const parseFeatureName = (eventName?: string): string | undefined => {
  if (eventName?.includes(':')) {
    const [featureName] = eventName.split(':');

    return featureName.toLowerCase();
  } else if (eventName?.includes('_')) {
    const [featureName] = eventName.split('_');

    return featureName.toLowerCase();
  }

  return eventName;
};
