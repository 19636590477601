import { useEffect, useState } from 'react';

import { IDListType, IDType } from './useCheckbox.types';

export type UseCheckArgs = {
  totalItemsList?: number;
  defaultSelected?: IDListType;
  defaultSelectedAll?: boolean;
};

const useCheck = ({ totalItemsList, defaultSelected = [], defaultSelectedAll = false }: UseCheckArgs = {}) => {
  const [selectedAll, setSelectedAll] = useState(defaultSelectedAll);
  const checkList: IDListType = [];

  const handleSelectedAll = () => {
    clearSelectedAll();
    setSelectedAll(!selectedAll);
  };

  const [selected, setSelected] = useState(defaultSelected);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const [allSelected, setAllSelected] = useState(false);

  const selectItems = () => {
    if (selectedAll) setSelectedAll(false);
  };

  useEffect(() => {
    if (selected.length === totalItemsList) {
      setSelectedAll(true);
    }
  }, [selected]);

  const isSelect = (id: IDType) => selected.includes(id);

  const filterSelected = () => checkList.filter((item) => selected.includes(item));

  const hasIncludeIndeterminate = () => {
    const filter = filterSelected();
    return filter.length > 0 && filter.length !== checkList.length;
  };

  const hasInclude = () => {
    const filter = filterSelected();
    return filter.length > 0 && filter.length === checkList.length;
  };

  useEffect(() => {
    setIsIndeterminate(hasIncludeIndeterminate());
  }, [selected, checkList]);

  useEffect(() => {
    let hasSelect = false;

    if (hasInclude()) {
      hasSelect = true;
    }

    setAllSelected(hasSelect);
  }, [selected, checkList]);

  const toggleSelection = (id: IDType) => {
    selectItems();
    const hasSelect = isSelect(id);
    const changedSelection = selected;

    if (hasSelect) {
      const key = selected.findIndex((k) => k === id);

      changedSelection.splice(key, 1);
    } else {
      changedSelection.push(id);
    }

    setSelected([...changedSelection]);
  };

  const clearSelected = () => {
    const filter = selected.filter((item) => !checkList.includes(item));
    setSelected(filter);
  };

  const fillSelected = () => {
    setSelected([...checkList, ...selected]);
  };

  const selectAll = () => {
    selectItems();
    (isIndeterminate || hasInclude() ? clearSelected : fillSelected)();
  };

  const clearSelectedAll = () => {
    setSelected([]);
  };

  return {
    selectedAll,
    allSelected,
    selectItems,
    clearSelected,
    clearSelectedAll,
    handleSelectedAll,
    selected,
    registerLeader: () => ({
      indeterminate: isIndeterminate,
      checked: allSelected,
      onChange: selectAll,
    }),
    register: (id: IDType) => {
      checkList.push(id);

      return {
        checked: isSelect(id),
        onChange: () => toggleSelection(id),
      };
    },
  };
};

export { useCheck };
