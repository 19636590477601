import { useState } from 'react';

type InitialValue = string;

function useLocalStorage(key: string, initialValue: InitialValue = '') {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);

      return item || initialValue;
    } catch (err) {
      return initialValue;
    }
  });

  const setItem = (value: string) => {
    setStoredValue(value);

    window.localStorage.setItem(key, value);
  };

  const removeItem = () => {
    setStoredValue(initialValue);

    window.localStorage.removeItem(key);
  };

  return [storedValue, setItem, removeItem] as const;
}

export { useLocalStorage };
