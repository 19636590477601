import * as Sentry from '@sentry/nextjs';
import type { SeverityLevel, User } from '@sentry/nextjs';

interface SendMessageArgs {
  type?: SeverityLevel;
  data?: any;
  user?: User;
  message: string;
  exception: any;
}

export const sendMessage = ({ message, data, type = 'info', user, exception }: SendMessageArgs): void => {
  Sentry.setUser(user ? user : null);
  Sentry.setContext('language', { dateLocale: Intl.DateTimeFormat().resolvedOptions().locale });
  if (data) Sentry.setContext('metadata', data);
  Sentry.captureMessage(message, type);
  Sentry.captureException(exception);
};
