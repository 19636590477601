import { PropsWithChildren } from 'react';

import { PageHead } from '../../PageHead/PageHead';
import { BaseLayoutProps } from './BaseLayout.type';

export const BaseLayout: React.FC<PropsWithChildren<BaseLayoutProps>> = ({ children, renderHeader, ...props }) => {
  const header = renderHeader?.();

  return (
    <>
      <PageHead {...props} />
      {header && header}
      {children}
    </>
  );
};
