export enum CardRoutes {
  INDEX = '/cartoes',
  BATCHES = '/cartoes/solicitacoes',
  BATCH = '/cartoes/lote/[externalId]/[batchId]',
  EMPLOYEE_DATA_VIEW = '/cartoes/colaborador/[employeeId]',
  REQUEST = '/cartoes/solicitacao',
  CONFIRM_ADDRESSES = '/cartoes/solicitacao/confirmar-endereco',
  REISSUE_CARD_REASON = '/cartoes/segunda-via/motivo',
  REISSUE_CARD_DELIVERY_ADDRESS = '/cartoes/segunda-via/entrega',
  REISSUE_CARD_CONFIRM = '/cartoes/segunda-via/confirmacao',
}
