import { IconSizesTypeMap } from './iconSizes.types';

export const iconSizes: IconSizesTypeMap<string> = {
  xl: '2rem',
  l: '1.5rem',
  m: '1.125rem',
  s: '1rem',
  xs: '0.75rem',
  xxs: '0.625rem',
};
