type IsEnable = (toggle: string) => boolean;
type BelongsTo = (list: string, toggle: string) => boolean;

export const featureToggleValidations = () => {
  const featureTogglesString = sessionStorage.getItem('_ifb_feature_toggles_');
  const featureToggles = featureTogglesString ? JSON.parse(featureTogglesString) : {};

  const isEnable: IsEnable = (toggle: string) => featureToggles?.toggles?.[toggle] || false;

  const belongsTo: BelongsTo = (list: string, toggle: string) =>
    featureToggles?.lists?.[list]?.some((l: string) => l === toggle) || false;

  const getToggleList = (toggle: string) => featureToggles?.lists?.[toggle] || [];

  return {
    isEnable,
    belongsTo,
    getToggleList,
  };
};
