import { AccessControlContext, UserContext } from '@benefits/common-services';
import { FeatureContext, ToggleContext } from '@benefits/features';
import { QUERY_CLIENT } from '@benefits/http-client';
import { ProductContext } from '@benefits/product-services';
import { RCSchemas, RemoteConfigClient, client } from '@benefits/remote-config';
import React, { useMemo } from 'react';

import { GetFederatedModuleProps, getFederatedModule } from './getFederatedModule';

type RemoteProps<Context = any> = {
  queryClient?: typeof QUERY_CLIENT;
  context?: Context;
  userContext?: Context;
  featureContext?: Context;
  featureTogglesContext?: Context;
  productContext?: Context;
  accessControlContext?: Context;
  draftContext?: Context;
  remoteConfigClient?: RemoteConfigClient<RCSchemas>;
  maintenanceFields?: string[];
  children?: React.ReactNode;
};

const RenderFederatedModule: React.FC<GetFederatedModuleProps & RemoteProps> = ({
  remote,
  component,
  errorBoundary,
  loadingComponent,
  context,
  maintenanceFields,
  ...props
}) => {
  const DynamicComponent: React.ComponentType<RemoteProps> = useMemo(
    () =>
      getFederatedModule({
        remote,
        component,
        errorBoundary,
        loadingComponent,
      }),
    [remote, component]
  );
  return (
    <DynamicComponent
      {...props}
      accessControlContext={AccessControlContext}
      context={context}
      featureContext={FeatureContext}
      featureTogglesContext={ToggleContext}
      maintenanceFields={maintenanceFields}
      productContext={ProductContext}
      queryClient={QUERY_CLIENT}
      remoteConfigClient={client}
      userContext={UserContext}
    >
      {props.children}
    </DynamicComponent>
  );
};

export { RenderFederatedModule };
