import { PropsWithChildren, createContext, useCallback } from 'react';
import { useQuery } from 'react-query';

import { getFeatureToggles } from './featureTogglesRequest';

type IsEnable = (toggle: string) => boolean;
type BelongsTo = (list: string, toggle: string) => boolean;

type ContextType = {
  isEnable: IsEnable;
  belongsTo: BelongsTo;
  getToggleList: (toggle: string) => string[];
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
} | null;

const ToggleContext = createContext<ContextType>(null);
ToggleContext.displayName = 'FeatureTogglesContext';

const ToggleStore: React.FC<PropsWithChildren> = (props) => {
  const { data, isLoading, isSuccess, isError } = useQuery('feature-toggles', getFeatureToggles, {
    keepPreviousData: true,
    onSuccess: (data) => {
      sessionStorage.setItem('_ifb_feature_toggles_', JSON.stringify(data));
    },
  });

  const isEnable = useCallback<IsEnable>((toggle: string) => data?.toggles?.[toggle] || false, [data?.toggles]);

  const belongsTo = useCallback<BelongsTo>(
    (list, toggle) => data?.lists?.[list]?.some((l) => l === toggle) || false,
    [data?.lists]
  );

  const getToggleList = useCallback((toggle: string) => data?.lists?.[toggle] || [], [data?.lists]);

  return (
    <ToggleContext.Provider
      value={{ isEnable, belongsTo, getToggleList, isFetching: isLoading, isSuccess, isError }}
      {...props}
    />
  );
};

export { ToggleContext, ToggleStore };
