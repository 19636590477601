import { useFeatureToggle } from '@benefits/features';
import { useMemo } from 'react';

import { useMarketplaceHide } from '../useMarketplaceHide/useMarketplaceHide';
import { useMarketplacePublicCompany } from '../useMarketplacePublicCompany/useMarketplacePublicCompany';
import { usePublicCompany } from '../usePublicCompany/usePublicCompany';

export const useMarketplaceShow = () => {
  const isMarketplacePublicCompany = useMarketplacePublicCompany();
  const isMarketplaceHideForThisGroup = useMarketplaceHide();
  const [isMarketplaceEnabled] = useFeatureToggle('MARKETPLACE_EXPERIENCE');
  const { isPublicCompany } = usePublicCompany();
  const showMarketplace = useMemo(() => {
    return isMarketplaceEnabled && (!isPublicCompany || isMarketplacePublicCompany) && !isMarketplaceHideForThisGroup;
  }, [isMarketplaceEnabled, isMarketplacePublicCompany, isPublicCompany, isMarketplaceHideForThisGroup]);

  return [showMarketplace];
};
