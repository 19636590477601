import { Header } from '@benefits/components';
import { LogoIfoodBeneficios, LogoIfoodBeneficiosShort } from '@benefits/crafts';
import { PropsWithChildren } from 'react';

import { Drawer } from '../../../Drawer/Drawer';
import { BaseLayout } from '../../BaseLayout';
import type { BaseLayoutProps } from '../../BaseLayout';
import { Container, FlexContainer } from './ContainerFull.styles';

export const ContainerFull: React.FC<PropsWithChildren<BaseLayoutProps>> = ({
  children,
  logo = <LogoIfoodBeneficios.IfoodBeneficiosSVG />,
  logoMobile = <LogoIfoodBeneficiosShort.IfoodBeneficiosShortSVG />,
  sidebar = true,
  ...baseProps
}) => {
  return (
    <BaseLayout {...baseProps}>
      <Header headerLogo={logo} headerMobileLogo={logoMobile} />
      {sidebar ? (
        <>
          <Drawer />
          <FlexContainer sidebar={sidebar}>
            <Container component="main" maxWidth="xl">
              {children}
            </Container>
          </FlexContainer>
        </>
      ) : (
        <FlexContainer sidebar={false}>{children}</FlexContainer>
      )}
    </BaseLayout>
  );
};
