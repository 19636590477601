import { BenefitProductType, ProductEnum, useProducts } from '@benefits/product-services';
import { useCallback, useMemo } from 'react';

export const useHasBenefits = () => {
  const products = useProducts();
  const hasBenefit = useCallback((benefit: BenefitProductType) => benefit in products, [products]);

  const hasBenefits = useMemo(
    () => products?.some((product) => Object.values(ProductEnum).includes(product as ProductEnum)),
    [products]
  );

  return { hasBenefits, hasBenefit };
};
