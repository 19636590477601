export {
  eventDispatcher,
  initializeEventDispatcher,
  globalEventMetadata,
  createEventDispatcher,
  allEventsDispatcher,
  updateAboyeurMetadata,
} from './client';

export { PLUGINS, PLUGIN_NAMES } from './plugins';

export * from './config';

export * from './utils/parseFeatureName';

export type { CardEventType, CardEventsType } from './card/card';
export { CardEvents } from './card/card';

export type { DashboardEventType, DashboardEventsType } from './dashboard/dashboard';
export { DashboardEvents } from './dashboard/dashboard';

export type { EmployeeEventType, EmployeeEventsType } from './employee/employee';
export { EmployeeEvents } from './employee/employee';

export type { ErrorEventType, ErrorEventsType } from './error/error';
export { ErrorEvents } from './error/error';

export type { NewsEventType, NewsEventsType } from './news/news';
export { NewsEvents } from './news/news';

export type { ReportEventType, ReportEventsType } from './report/report';
export { ReportEvents } from './report/report';

export type { IfoodCardEventType, IfoodCardEventsType } from './ifood-card/ifood-card';
export { IfoodCardEvents } from './ifood-card/ifood-card';

export type { EventType } from './all-events/all-events';
export { AllEvents } from './all-events/all-events';

export { HelpEvents } from './help/help';

export { HeaderEvents } from './header/header';

export { NotificationEvents } from './notification/notification';

export { AuthenticationEvents } from './authentication/authentication';

export { MarketplaceEvents } from './marketplace/marketplace';

export { LicenseEvents } from './license/license';
