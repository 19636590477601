import { HttpMethods, HttpStatusCodes, UnexpectedError, makeRequest } from '@benefits/http-client';

import { BASE_URL, ENDPOINTS } from './endpoints';
import { ProductsResponseBody } from './productModel';

export const getProducts = async () => {
  const response = await makeRequest<ProductsResponseBody>({
    baseURL: BASE_URL,
    method: HttpMethods.GET,
    url: ENDPOINTS.v1.products.get,
  });

  switch (response.status) {
    case HttpStatusCodes.OK:
      return response.body;

    default:
      throw new UnexpectedError(response?.error);
  }
};
