import { AcceptedRoutesType } from '@benefits/router-services/src/hooks/types';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

import { MenuIconType } from '../MenuIcons';
import { Button, Icon, Title } from './MenuButton.styles';

type MenuButtonProps = {
  onClick?: () => void | null;
  pathname: AcceptedRoutesType;
  icon: MenuIconType;
  title: string;
  onMenuClickEvent: (pageDestination?: string) => void;
  isLegacy?: boolean;
};

const MenuButton: React.FC<PropsWithChildren<MenuButtonProps>> = ({
  onClick,
  isLegacy,
  pathname,
  icon,
  title,
  onMenuClickEvent,
}) => {
  const route = useRouter();
  const [, currentPath] = route.pathname.split('/');

  const redirectTo = () => {
    onMenuClickEvent(pathname);
    if (isLegacy) {
      window.location.assign(pathname);
    } else {
      route.push(pathname);
    }
  };
  const activeRoute = pathname && currentPath.includes(pathname.replace('/', ''));
  return (
    <Button active={activeRoute} onClick={onClick ?? redirectTo}>
      <Icon alt="" src={activeRoute ? icon.selected : icon.normal} />
      <Title>{title}</Title>
    </Button>
  );
};

export { MenuButton };
