import { StrategyEnum } from '@benefits/auth-services';
import { UserContext } from '@benefits/common-services';
import { useContext } from 'react';

import { UsePublicCompanyReturn } from './usePublicCompany.types';

export const usePublicCompany = (): UsePublicCompanyReturn => {
  const context = useContext(UserContext);

  const isPublicCompany = context?.state?.user?.group?.metadata?.strategy === StrategyEnum.PublicCompany;

  return { isPublicCompany };
};
