/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosRequestConfig } from 'axios';

import { httpClient } from '../httpClient/httpClient';

export type CustomError = {
  data: { code: string };
} & Error;

export type Response<BodyType> = {
  body: BodyType;
  status: number;
  error?: CustomError;
};

export type RequestType = Omit<AxiosRequestConfig, 'data'> & {
  body?: AxiosRequestConfig['data'];
};

export const makeRequest = async <BodyType>({ body: data, ...config }: RequestType): Promise<Response<BodyType>> => {
  try {
    const response = await httpClient({ ...config, data });

    return { body: response.data, status: response.status };
  } catch (error: any) {
    return {
      body: error?.response?.data,
      status: error?.response?.status,
      error: error?.response,
    };
  }
};
