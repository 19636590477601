import { useContext } from 'react';

import * as featureToggles from '../../toggle/featureToggles';
import { ToggleContext } from '../../toggle/ToggleStore';

export const useFeatureToggle = (flag: keyof typeof featureToggles) => {
  const featureTogglesContent = useContext(ToggleContext);

  const isFlagFeatureToggle = Boolean(featureTogglesContent?.isEnable(flag)) && featureTogglesContent?.isSuccess;

  return [isFlagFeatureToggle];
};
