import { variables } from '@benefits/environment';

export const API = '/portal';
export const BASE_URL = `${variables.BFF_URL}${API}`;

export const ENDPOINTS = {
  v1: {
    products: {
      get: '/v1/group/products',
    },
    productsMarketplace: '',
  },
};
