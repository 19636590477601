export enum ProductEnum {
  MEAL_VOUCHER = 'MEAL_VOUCHER',
  MOBILITY_VOUCHER = 'MOBILITY_VOUCHER',
  CULTURE_VOUCHER = 'CULTURE_VOUCHER',
  EDUCATION_VOUCHER = 'EDUCATION_VOUCHER',
  HOME_OFFICE_VOUCHER = 'HOME_OFFICE_VOUCHER',
  PHARMACY_VOUCHER = 'PHARMACY_VOUCHER',
  IFOOD_CARD = 'IFOOD_CARD',
  REWARD_VOUCHER = 'REWARD_VOUCHER',
}

export enum OfficeProducts {
  IFOOD_CORP = 'IFOOD_CORP',
}

export type BenefitsProductsType = typeof ProductEnum;
export type BenefitProductType = keyof BenefitsProductsType;

export type OfficeProductsType = typeof OfficeProducts;
export type OfficeProductType = keyof OfficeProductsType;

export type Products = ProductEnum[];

export type ProductsResponseBody = Products;
