import { Container as ContainerDS } from '@benefits/components';
import styled, { css } from 'styled-components';

export const DashboardWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.spacingMd} 0 ${({ theme }) => theme.spacing.spacing4xl} 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.spacingLg};
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  top: ${({ theme }) => theme.component.navBar.root.minHeight};
  min-height: ${({ theme }) => css`calc(100vh - ${theme.component.navBar.root.minHeight})`};
`;

export const Container = styled(ContainerDS)`
  display: flex;
  padding-inline-start: max(79px, env(safe-area-inset-left));
  padding-inline-end: max(64px, env(safe-area-inset-right));
`;
