import { Icons } from '@benefits/crafts';
import { useCallback, useEffect, useState } from 'react';

import { colors, iconVariant } from '../Snackbar.config';
import { Button, Container, Content, IconWrapper, Link, Title } from './Snack.styles';
import { SnackProps } from './Snack.types';

export const Snack: React.FC<SnackProps> = ({
  title,
  link,
  variant = 'attention',
  autoHideTime = 6000,
  close = true,
}) => {
  const [display, setDisplay] = useState(true);

  const Icon = iconVariant[variant];

  const closeSnack = useCallback(() => {
    setDisplay(false);
  }, []);

  useEffect(() => {
    if (autoHideTime) {
      const autoHideTimeout = setTimeout(() => {
        setDisplay(false);
      }, autoHideTime);

      return () => {
        clearTimeout(autoHideTimeout);
      };
    }
  }, [autoHideTime]);

  if (!display) return null;

  return (
    <Container aria-hidden={!display} role="alert" variant={variant}>
      <IconWrapper>
        <Icon iconColor={colors[variant].color} size="sm" />
      </IconWrapper>

      <Content>
        <Title>{title}</Title>

        {link && (
          <Link href={link.href} rel="noopener noreferrer" title={link.text}>
            {link.text}
          </Link>
        )}

        {close && (
          <Button aria-label="Fechar" title="Fechar" onClick={closeSnack}>
            <Icons.CrossOutline size="sm" />
          </Button>
        )}
      </Content>
    </Container>
  );
};
