import RemoteConfig from '@ifood/faster-remote-config';
import { makeClient } from '@ifood/react-faster-remote-config';

import pkg from '../package.json';
import { RCSchemas } from './schemas/schemas';

export const client = makeClient<RCSchemas>(
  typeof window !== 'undefined'
    ? RemoteConfig.start({
        appKey: process.env.NEXT_PUBLIC_FASTER_KEY ?? '',
        appVersion: pkg.version,
      })
    : new RemoteConfig('')
);
