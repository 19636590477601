export enum EmployeeRoutes {
  INDEX = '/colaboradores',
  INDEX_DRAFT = '/colaboradores#rascunhos',
  ADD_SPREADSHEET = '/colaboradores/rascunho/[draftId]/adicionar-planilha',
  DRAFT_PROCESSING = '/colaboradores/rascunho/[draftId]/processando',
  VALID_SPREADSHEET = '/colaboradores/rascunho/[draftId]/validar-planilha',
  COLUMN_MAPPER = '/colaboradores/rascunho/[draftId]/mapeamento-de-colunas',
  CONFIRM_DATA = '/colaboradores/rascunho/[draftId]/confirmar-dados',
  DRAFT_PROCESSED = '/colaboradores/rascunho/[draftId]/processado',
  DRAFT_PROMOTING = '/colaboradores/rascunho/[draftId]/promovendo',
  DRAFT_DETAILS = '/colaboradores/rascunho/[draftId]',
  CONFIGURE = '/colaboradores/configurar',
  REQUIRED_DATA = '/colaboradores/dados-obrigatorios',
  INSERT_PROCESSING = '/colaboradores/processando-dados',
  REGISTRATION_TYPE = '/colaboradores/tipo-de-cadastro',
  INSERT_DATA = '/colaboradores/inserir-dados',
  DRAFT_EMPLOYEE = '/colaboradores/rascunhos',
  PROMOTE_RECHARGE = '/colaboradores/rascunho/[draftId]/criando-recarga/[orderId]',
  NOT_AVAILABLE_DRAFT = '/colaboradores/rascunho/indisponivel',

  LEGACY_INDEX = '/v3/employees',
  LEGACY_EDIT_FORM = '/v3/employees/edit/[employeeId]',
  LEGACY_CREATE_FORM = '/v3/employees/create',
}
