import * as SentryLogger from '@benefits/common-services';
import { Auth } from '@benefits/router-services';
import { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import Router from 'next/router';

import { HttpStatusCodes } from '../constants';

type AuthRole = string;

type UserAuthProduct = string;

type AuthRoleCollection = AuthRole[];

type UserAuthProductCollection = UserAuthProduct[];

export type JwtPayload = {
  USER_ID: string;
  USER_NAME: string;
  GROUP_ID: string;
  ROLES: AuthRoleCollection;
  PRODUCTS: UserAuthProductCollection;
  iss: string;
  exp: number;
  iat: number;
  sub: string;
};

export const getDecodedJWT = (encodedToken: string): JwtPayload => {
  return jwt_decode(encodedToken);
};

export const isTokenExpired = (encodedToken: string) => {
  try {
    const jwt = getDecodedJWT(encodedToken);
    if (jwt.exp < Date.now() / 1000) {
      return true;
    }
  } catch (e) {
    return true;
  }

  return false;
};

export const redirectUnauthorized = (error: AxiosError) => {
  const token = error.request?.headers['Authorization'] || error.request?.headers['authorization'];
  if (error?.response?.status === HttpStatusCodes.UNAUTHORIZED) {
    if (token && !isTokenExpired(token)) {
      SentryLogger.sendMessage({
        message: `${error.response?.status} - ${JSON.stringify(error.response?.data)} ${error.request?.path}`,
        exception: error,
        type: 'error',
      });
    }
    window.localStorage.clear();
    Router.push(Auth.LOGIN);
  }

  return Promise.reject(error);
};
