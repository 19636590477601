export * from 'react-popper';

//@TODO: remove this and refactor its usages to use the design system one
// eslint-disable-next-line import/export
export * from '@ifood/fits-components';

export { ErrorBoundary } from './src/ErrorBoundary/ErrorBoundary';

export { News } from './src/News/News';
export { NEWS_CONFIG, NEWS_CONFIG_CARDS } from './src/News/News.config';
export { NewsLottie } from './src/News/NewsLottie';
export type { NewsProps, NewsType, NewsPathType } from './src/News/News.types';

export type { BreadcrumbsProps } from './src/Breadcrumbs/Breadcrumbs';
//@TODO: remove this and refactor its usages to use the design system one
// eslint-disable-next-line import/export
export { Breadcrumbs } from './src/Breadcrumbs/Breadcrumbs';

export { AppLayout } from './src/layouts/AppLayout';

export { EmptySearch } from './src/EmptySearch/EmptySearch';

export { BaseLayout } from './src/layouts/BaseLayout';
export { CraftLayout } from './src/layouts/CraftLayout';
export { LoggedLayout } from './src/layouts/LoggedLayout';
export { ContainerLayout } from './src/layouts/ContainerLayout';
export { DashboardLayout } from './src/layouts/LoggedLayout/DashboardLayout/DashboardLayout';

export { Loading } from './src/Loading/Loading';
export type { LoadingProps } from './src/Loading/Loading';

export { Radio } from './src/FormElements/Radio/Radio';
export { RadioGroup } from './src/FormElements/Radio/RadioGroup/RadioGroup';
export type { RadioGroupOptionsType } from './src/FormElements/Radio/RadioGroup/RadioGroup.types';

export { SpacingProvider } from './src/SpacingProvider/SpacingProvider';

export { Snackbar } from './src/Snackbar';
export type { SnackProps, SnackbarProps } from './src/Snackbar';

export { Stepper } from './src/Stepper/Stepper';
export type { StepProps, StepperProps, StepVariantType } from './src/Stepper/Stepper.types';

//@TODO: remove this and refactor its usages to use the design system one
// eslint-disable-next-line import/export
export { Header } from './src/Header/Header';

export { RenderFederatedModule } from './src/RenderMicroFrontend/RenderFederatedModule';
export { WithWaveLayout } from './src/layouts/LoggedLayout/WithWave';
