import { Can, UserContext, useMarketplaceShow } from '@benefits/common-services';
import { debounce, isServer } from '@benefits/common-utils';
import { useFinancialNXP, useRechargesNXP } from '@benefits/features';
import { IfoodCardRoutes, Report } from '@benefits/router-services';
import { AdminRoutes } from '@benefits/router-services/src/routes/admin';
import { CardRoutes } from '@benefits/router-services/src/routes/card';
import { CollectiveAgreementRoutes } from '@benefits/router-services/src/routes/collectiveAgreement';
import { CompanyRoutes } from '@benefits/router-services/src/routes/company';
import { DashboardRoutes } from '@benefits/router-services/src/routes/dashboard';
import { EmployeeRoutes } from '@benefits/router-services/src/routes/employee';
import { FinancialRoutes } from '@benefits/router-services/src/routes/financial';
import { MarketplaceRoutes } from '@benefits/router-services/src/routes/marketplace';
import { RechargeRoutes } from '@benefits/router-services/src/routes/recharge';
import { eventDispatcher } from '@benefits/tracking';
import { MenuEvents } from '@benefits/tracking/src/events/events';
import { useRouter } from 'next/router';
import { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Container, Content, Footer, MenuContainer, MenuItem } from './Drawer.styles';
import { MenuButton } from './MenuButton/MenuButton';
import { MenuIcons } from './MenuIcons';

export interface DrawerProps {
  isStepper?: boolean;
}

const Drawer: React.FC<PropsWithChildren<DrawerProps>> = ({ isStepper }) => {
  const router = useRouter();
  const context = useContext(UserContext);
  const user = context?.state?.user;
  const { isRechargesNXP } = useRechargesNXP();
  const [collapsed, setCollapsed] = useState(router.pathname === DashboardRoutes.DASHBOARD);
  const dispatcher = isServer() ? undefined : eventDispatcher.events.allEvents;
  const { isFinancialNxpEnabled } = useFinancialNXP({ groupId: user?.group?.id });

  const [marketplaceEnabled] = useMarketplaceShow();

  const ref = useRef<HTMLDivElement>(null);

  const sessionData = useMemo(
    () => ({
      UserID: user?.userId,
      GroupID: user?.group?.id,
    }),
    [user]
  );

  const onViewMenu = debounce(() => {
    if (dispatcher) {
      const eventFunction = dispatcher[MenuEvents.VIEW];
      if (eventFunction) {
        eventFunction({
          ...sessionData,
          menuState: 'open',
        });
      }
    }
  }, 2000);

  const onMenuClick = (pageDestination?: string) => {
    if (dispatcher) {
      const eventFunction = dispatcher[MenuEvents.VIEW_CLICK];
      if (eventFunction) {
        eventFunction({
          ...sessionData,
          pageView: router.pathname,
          pageDestionation: pageDestination,
          menuState: 'open',
        });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCollapsed(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const onMouseOver = () => {
      onViewMenu();
    };
    ref.current?.addEventListener('mouseover', onMouseOver);
    return () => {
      ref.current?.removeEventListener('mouseover', onMouseOver);
    };
  }, []);

  return (
    <Container>
      <Content collapsed={collapsed} isStepper={isStepper} ref={ref}>
        <MenuContainer>
          <MenuItem>
            <MenuButton
              icon={MenuIcons.dashboard}
              pathname={DashboardRoutes.DASHBOARD}
              title="Início"
              onMenuClickEvent={onMenuClick}
            />
          </MenuItem>
          <>
            {marketplaceEnabled && (
              <Can namespace="marketplace">
                <MenuItem>
                  <MenuButton
                    icon={MenuIcons.marketplace}
                    pathname={MarketplaceRoutes.INDEX}
                    title="Produtos"
                    onMenuClickEvent={onMenuClick}
                  />
                </MenuItem>
              </Can>
            )}

            <Can namespace="recharges">
              <MenuItem>
                <MenuButton
                  icon={MenuIcons.recharge}
                  isLegacy={!isRechargesNXP}
                  pathname={isRechargesNXP ? RechargeRoutes.INDEX : RechargeRoutes.LEGACY_INDEX}
                  title="Recargas"
                  onMenuClickEvent={onMenuClick}
                />
              </MenuItem>
            </Can>
            <Can namespace="employees">
              <MenuItem>
                <MenuButton
                  icon={MenuIcons.employee}
                  pathname={EmployeeRoutes.INDEX}
                  title="Colaboradores"
                  onMenuClickEvent={onMenuClick}
                />
              </MenuItem>
            </Can>
            <Can namespace="cards">
              <MenuItem>
                <MenuButton
                  icon={MenuIcons.cards}
                  pathname={CardRoutes.INDEX}
                  title="Cartões"
                  onMenuClickEvent={onMenuClick}
                />
              </MenuItem>
            </Can>
            <Can namespace="financial">
              <MenuItem>
                <MenuButton
                  icon={MenuIcons.financial}
                  isLegacy={!isFinancialNxpEnabled}
                  pathname={isFinancialNxpEnabled ? FinancialRoutes.INDEX : FinancialRoutes.LEGACY_INDEX}
                  title="Financeiro"
                  onMenuClickEvent={onMenuClick}
                />
              </MenuItem>
            </Can>
            <Can namespace="reports">
              <MenuItem>
                <MenuButton
                  icon={MenuIcons.report}
                  pathname={Report.DASHBOARD}
                  title="Relatórios"
                  onMenuClickEvent={onMenuClick}
                />
              </MenuItem>
            </Can>
          </>
          <Can namespace="access">
            <MenuItem>
              <MenuButton
                icon={MenuIcons.admin}
                pathname={AdminRoutes.INDEX}
                title="Gestão de Acessos"
                onMenuClickEvent={onMenuClick}
              />
            </MenuItem>
          </Can>
          <Can namespace="companies">
            <MenuItem>
              <MenuButton
                icon={MenuIcons.company}
                pathname={CompanyRoutes.COMPANIES}
                title="Empresas"
                onMenuClickEvent={onMenuClick}
              />
            </MenuItem>
          </Can>
          <Can namespace="collective-agreements">
            <MenuItem>
              <MenuButton
                isLegacy
                icon={MenuIcons.collectiveAgreement}
                pathname={CollectiveAgreementRoutes.LEGACY_INDEX}
                title="Convenção"
                onMenuClickEvent={onMenuClick}
              />
            </MenuItem>
          </Can>
        </MenuContainer>
        <Footer>
          <MenuContainer>
            <Can namespace="ifood-card">
              <MenuItem>
                <MenuButton
                  icon={MenuIcons.ifoodCard}
                  pathname={IfoodCardRoutes.INDEX}
                  title="iFood Card"
                  onMenuClickEvent={onMenuClick}
                />
              </MenuItem>
            </Can>
          </MenuContainer>
        </Footer>
      </Content>
    </Container>
  );
};

export { Drawer };
