import { EventParamsType, EventsDictionary } from '../types';

export enum DashboardEvents {
  HomeView = 'Home:View',
  HomeOpenRechargesClick = 'Home:OpenRecharges_Click',
  HomeOpenFinancialClick = 'Home:OpenFinancial_Click',
  HomeOpenEmployeeClick = 'Home:OpenEmployee_Click',
  HomeOpenCardsClick = 'Home:OpenCards_Click',
  HomeRegisterEmployeeClick = 'Home:RegisterEmployee_Click',
  HomeLastRechargeActionClick = 'Home:LastRecharge_Action_Click',
  HomeLastRechargeDownloadClick = 'Home:LastRecharge_Download_Click',
  HomeLastRechargeNewRechargeClick = 'Home:LastRecharge_NewRecharge_Click',
  HomeLastRechargeGenerateReportClick = 'Home:LastRecharge_GenerateReport_Click',
  HomeCardEmissionClick = 'Home:CardEmission_Click',
  HomePATWalletDepositClick = 'Home:PATWallet_Deposit_Click',
  HomeNOTPATWalletDepositClick = 'Home:NOTPATWallet_Deposit_Click',
  HomeOpenIFoodCardClick = 'Home:OpeniFoodCard_Click',
  HomeBuyIFoodCardClick = 'Home:BuyiFoodCard_Click',
  HomeOpenRecentUpdateClick = 'HomeOpenRecentUpdate_Click',
  HomeOpenUpdatesClick = 'Home:OpenUpdates_Click',
  OnboardingWelcomeView = 'Onboarding:Welcome_View',
  OnboardingView = 'Onboarding:View',
  OnboardingRegisterEmployeeClick = 'Onboarding:RegisterEmployee_Click',
  OnboardingCardEmissionClick = 'Onboarding:CardEmission_Click',
  OnboardingOpenEmployeeClick = 'Onboarding:OpenEmployee_Click',
  OnboardingFirstRechargeClick = 'Onboarding:FirstRecharge_Click',
  OnboardingOpenCardsClick = 'Onboarding:OpenCards_Click',
  OnboardingBannerValuesView = 'Onboarding:BannerValues_View',
  SetupView = 'Setup:SetupView',
  Setup_admin_Click = 'Setup:SetupAdminClick',
  Setup_marketplace_Click = 'Setup:SetupMarketplaceClick',
  Setup_employee_Click = 'Setup:SetupEmployeeClick',
  Setup_card_Click = 'Setup:SetupCardClick',
  Setup_recharge_Click = 'Setup:SetupRechargeClick',
  Setup_company_Click = 'Setup:SetupCompanyClick',
  SetupMoreClick = 'Setup:SetupMoreClick',
  SetupMoreLaterClick = 'Setup:SetupMoreLaterClick',
  SetupBackClick = 'Setup:SetupBackClick',
  SetupAccessDashboard = 'Setup:SetupAccessDashboard',
  VisualizacaoView = 'Visualizacao:View',
  AdSeeLaterClick = 'ad:SeeLater_click',
  AdMainCTAClick = 'ad:MainCTA_click',
}

export type DashboardEventsType = typeof DashboardEvents;

export type DashboardEventType = keyof DashboardEventsType;

export type DashboardEventTypeCollection = DashboardEventType[];

const DashboardEventKeys = Object.keys(DashboardEvents) as DashboardEventTypeCollection;

export const dashboard: EventsDictionary = DashboardEventKeys.reduce((collection, eventKey) => {
  const eventName = DashboardEvents[eventKey];

  return {
    ...collection,
    [eventName]: <DashboardEventParams>(params: EventParamsType<DashboardEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
