import NextHead from 'next/head';

export interface PageHeadProps {
  title?: string;
  description?: string;
  shareImage?: string;
  pagePath?: string;
}

const PageHead: React.FC<PageHeadProps> = ({
  shareImage,
  title = 'iFood Benefícios | O vale-alimentação e vale-refeição do iFood!',
  description = 'iFood Benefícios é o vale-alimentação e vale-refeição do iFood. Aceito em +4M de estabelecimentos com o cartão Elo ou delivery!',
  pagePath = '/',
}) => {
  return (
    <NextHead>
      <title>{title}</title>
      <meta content={description} name="description" />
      <meta content="noindex,nofollow" name="robots" />
      <meta content={title} property="og:title" />
      {shareImage && <meta content={shareImage} property="og:image" />}
      <meta content={description} property="og:description" />
      <meta content={`https://empresas-app.ifood.com.br/new${pagePath}`} property="og:url" />
      <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
      <meta content="width=device-width, initial-scale=1.0, minimum-scale=1.0" name="viewport" />
      <link href="/favicon.ico" rel="shortcut icon" />
      <meta content="#ea1e2c" name="theme-color" />
      <meta content="iFood Benefícios" name="application-name" />
      <meta content="website" property="og:type" />
      <meta content="iFood Benefícios" property="og:site_name" />
      <meta content="pt_BR" property="og:locale" />
      <meta content="ifood" name="twitter:card" />
      <meta content="summary" name="twitter:site" />
    </NextHead>
  );
};

export { PageHead };
