import { Badge as BadgeFits, Button, Heading } from '@benefits/components';
import styled, { keyframes } from 'styled-components';

const isProduction = process.env.NODE_ENV === 'production';

export const Waves = styled.div`
  background: url(${isProduction ? '/new' : ''}/assets/images/waves/wave-white.svg);
  height: 100vh;
  width: 81px;
`;

export const Title = styled(Heading)`
  color: white;
`;

export const Description = styled.div`
  color: white;

  p,
  li {
    color: white;
  }
`;

export const ButtonCancel = styled(Button)`
  color: white;

  &:hover::before {
    color: white;
    background-color: #2c2c2c;
  }
  &:hover {
    color: white;
    background-color: #2c2c2c;
  }
`;

export const ButtonSend = styled(Button)`
  background-color: white;
  color: #1a1a1a;
  transition: background-color 200ms;
  &::before {
    color: #1a1a1a;
    background-color: white;
  }
  &:hover::before {
    color: #1a1a1a;
    background-color: #e0e0e0;
  }
  &:hover {
    color: #1a1a1a;
    background-color: #e0e0e0;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 32px;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

export const Badge = styled(BadgeFits)`
  background: #fef8ec;
  height: auto;
  display: inline-flex;
  align-items: center;
  padding-right: 12px;
  gap: 2px;

  & > span {
    display: flex;
  }
`;

export const NewsLetter = styled.main`
  display: flex;
`;

export const DescriptionWrapper = styled.section`
  height: 100vh;
  width: calc(50% - 81px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a1a1a;
`;

export const DescriptionContent = styled.article`
  display: grid;
  gap: 24px;
  width: 80%;
  h1 {
    font-size: 2rem;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const IllustrationContainer = styled.section`
  height: 100vh;
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  img,
  svg {
    width: 100%;
    height: 80%;
    max-height: 100%;
    animation: 1.5s ${fadeIn} ease-out;
  }
`;
