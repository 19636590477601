import { Heading, Text } from '@ifood/fits-components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: span 12;
`;

export const TextWrapper = styled.div`
  margin-top: 96px;
  margin-bottom: 24px;
`;

export const ActionText = styled(Text)`
  color: #1a1a1a;
`;

export const Title = styled(Heading)`
  color: #1a1a1a;
  font-weight: bold;
`;
