import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { QUERY_CACHE_TIME } from '../constants';

export const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: QUERY_CACHE_TIME,
    },
  },
});

const Provider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <QueryClientProvider contextSharing client={QUERY_CLIENT}>
      {children}
    </QueryClientProvider>
  );
};

export { Provider as QueryClientProvider };
