import { Button, Heading, Text } from '@benefits/components';

import { Illustration, Wrapper } from './Unexpected.styles';

export interface UnexpectedProps {
  onRetry?: () => void;
  title?: string;
  message?: string;
}

const DEFAULT_TITLE = 'Opa, alguma coisa deu errado!';
const DEFAULT_MESSAGE = 'Não conseguimos carregar seus dados';

export const Unexpected: React.FC<UnexpectedProps> = ({
  onRetry,
  title = DEFAULT_TITLE,
  message = DEFAULT_MESSAGE,
  ...props
}) => {
  return (
    <Wrapper>
      <Illustration {...props} />
      <Heading variant="heading2">{title}</Heading>
      <Text>{message}</Text>
      {onRetry && (
        <Button shapeStyle="pill" type="button" onClick={onRetry}>
          Tentar novamente
        </Button>
      )}
    </Wrapper>
  );
};
