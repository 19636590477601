import { Auth } from '@benefits/router-services';
import Router from 'next/router';
import { useEffect } from 'react';

import { NEWS_CONFIG } from './News.config';
import { NewsPathType, NewsProps, NewsType } from './News.types';
import { NewsDark } from './NewsDark/NewsDark';
import { NewsLight } from './NewsLight/NewsLight';

const News: React.FC<NewsProps> = ({ onPageView, onInterest, onSeeLater, path }) => {
  const config: NewsType = NEWS_CONFIG[path as NewsPathType];

  useEffect(() => {
    if (!config) {
      Router.push(Auth.LOGIN);
    }
  }, []);

  return config ? (
    config?.dark ? (
      <NewsDark config={config} onInterest={onInterest} onPageView={onPageView} onSeeLater={onSeeLater} />
    ) : (
      <NewsLight config={config} onInterest={onInterest} onPageView={onPageView} onSeeLater={onSeeLater} />
    )
  ) : null;
};

export { News };
