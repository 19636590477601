import React from 'react';

import { useMarketplaceHide } from '../hooks/useMarketplaceHide/useMarketplaceHide';
import { useMarketplacePublicCompany } from '../hooks/useMarketplacePublicCompany/useMarketplacePublicCompany';
import { usePublicCompany } from '../hooks/usePublicCompany/usePublicCompany';

export const withPublicCompanyAccess = <P extends object>(
  Component: React.ComponentType<P>,
  ForbiddenComponent: React.ComponentType
) => {
  const PrivateComponent = (props: P) => {
    const { isPublicCompany } = usePublicCompany();
    const isMarketplaceHide = useMarketplaceHide();
    const isMarketplacePublicCompany = useMarketplacePublicCompany();

    if ((isPublicCompany && !isMarketplacePublicCompany) || isMarketplaceHide) return <ForbiddenComponent />;

    return <Component {...props} />;
  };

  return PrivateComponent;
};
