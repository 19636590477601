import { SnackProps, SnackbarProps } from '@benefits/components';
import { PositionType } from '@benefits/components/src/Snackbar/Group/Group.types';
import { useMessage } from '@benefits/message';
import { useState } from 'react';

type SnacksType = SnackbarProps['snacks'];

type SnackBarState = {
  snacks: SnacksType;
  position?: PositionType;
};

type SaveSnackProps = {
  snack: SnackProps;
  position?: PositionType;
};

type UseSnackbarType = (position?: PositionType) => {
  saveSnacks: (saveSnack: SaveSnackProps) => void;
  dispatch: any;
  snacks: SnacksType;
  position?: PositionType;
};

const useSnackbar: UseSnackbarType = (position?: PositionType) => {
  const { publish } = useMessage('snackbar');
  const [snackBar, setSnackBar] = useState<SnackBarState>({
    snacks: [],
    position: 'bottom-right',
  });

  const saveSnacks = (saveSnack: SaveSnackProps) => {
    setSnackBar((prevState) => ({
      snacks: [...prevState.snacks, saveSnack.snack],
      position: saveSnack?.position,
    }));
  };

  const dispatch = (snack: any) => {
    publish({ snack, position });
  };

  return { saveSnacks, snacks: snackBar.snacks, position: snackBar.position, dispatch };
};

export { useSnackbar };
