import type { BaseLayoutProps } from '../BaseLayout/BaseLayout.type';
import { ContainerFull } from './ContainerFull/ContainerFull';
import { WithStepper } from './WithStepper/WithStepper';
import type { WithStepperProps } from './WithStepper/WithStepper.type';

interface LayoutComposition {
  Stepper: React.FC<WithStepperProps>;
}

const LoggedLayout: React.FC<BaseLayoutProps> & LayoutComposition = (props) => <ContainerFull {...props} />;

LoggedLayout.Stepper = WithStepper;

export { LoggedLayout };
