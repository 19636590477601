import { SpacesTypeMap } from './spaces.types';

export const spaces: SpacesTypeMap<string> = {
  '4xl': '4rem',
  '3xl': '3rem',
  xxl: '2.5rem',
  xl: '2rem',
  l: '1.5rem',
  m: '1rem',
  s: '0.5rem',
  xs: '0.25rem',
};
