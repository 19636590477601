import { EventParamsType, EventsDictionary } from '../types';

export enum RechargeEvents {
  RechargesListView = 'recharges:list_view',
  RechargesCreateNewClick = 'recharges:create_new_click',
  RechargesFilterClick = 'recharges:filter_click',
  RechargesCancelRechargeClick = 'recharges:cancel_recharge_click',
  RechargesListCancelAndCloneRechargeClick = 'recharges:listCancelAndCloneRecharge_click',
  RechargesListCancelRechargeView = 'recharges:listCancelRecharge_view',
  RechargesTypeView = 'recharges:rechargeType_view',
  RechargesCreateFromSheetClick = 'recharges:create_from_sheet_click',
  RechargesCreateFromBaseClick = 'recharges:create_from_base_click',
  RechargesBenefitsSelectScreenView = 'recharges:benefits_select_screen_view',
  RechargesBenefitsSelectedClick = 'recharges:benefits_selected_click',
  RechargesBenefitsCancelRechargeClick = 'recharges:benefits_cancel_recharge_click',
  RechargesRemoveColabListCallBack = 'recharges:remove_colab_list_callback',
  RechargesParticipantSelectionScreenView = 'recharges:participantSelectionScreen_view',
  RechargesContinueOnThisRechargeClick = 'recharges:continue_on_this_recharge_click',
  RechargesRemoveEmployeeClick = 'recharges:remove_employee_click',
  RechargesEmployeeInlineValueAdjustClick = 'recharges:employee_inline_value_adjust_click',
  RechargesFilterEmployeeParticipantsClick = 'recharges:filter_employee_participants_click',
  RechargesSearchEmployeeParticipantsClick = 'recharges:search_employee_participants_click',
  RechargesContinueEmployeeListClick = 'recharges:continue_employeeList_click',
  RechargesEmptyValuesBackAndChangeView = 'recharges:empty_values_back_and_change_view',
  RechargesEmptyValuesBackAndChangeClick = 'recharges:empty_values_back_and_change_click',
  RechargesSaveToLaterClick = 'recharges:save_to_later_click',
  RechargesPaymentContinueClick = 'recharges:payment_continue_click',
  RechargesInvalidDistributionDateError = 'recharges:invalid_distribution_date_error',
  RechargesUnvalidDistributionInformDateClick = 'recharges:unvalid_distribution_inform_date_click',
  RechargesSaveAndContinueLaterClick = 'recharges:save_and_continue_later_click',
  RechargesPaymentConfigurationContinueLatterClick = 'recharges:payment_configuration_continue_latter_click',
  RechargesPaymentConfigurationErrorDateView = 'recharges:payment_configuration_error_date_view',
  RechargesPaymentSaveAndContinueLaterBannerClick = 'recharges:payment_save_and_continue_later_banner_click',
  RechargesPaymentReturnBannerClick = 'recharges:payment_return_banner_click',
  RechargesSummaryScheduleClick = 'recharges:summary_schedule_click',
  RechargesCodeBarCopyClick = 'recharges:code_bar_copy_click',
  RechargesBackToMainFromBillScreenClick = 'recharges:back_to_main_from_bill_screen_click',
  RechargesDownloadInvoicePdfClick = 'recharges:downloadInvoicePdf_click',
  RechargesBackToMainFromInvoiceScreenClick = 'recharges:backToMainFromInvoiceScreen_click',
  RechargesSummaryDistributeTodayClick = 'recharges:summary_distribute_today_click',
  RechargesDetailsCopyIDClick = 'recharges:detailsCopyID_click',
  RechargesDetailsActionCopyIDClick = 'recharges:details_actionCopyID_click',
  RechargesCopyIDClick = 'recharges:CopyID_click',
  RechargesActionCopyIDClick = 'recharges:actionCopyID_click',
  RechargesActionsEditClick = 'recharges:actions_edit_click',
  RechargesActionsCancelClick = 'recharges:actions_cancel_click',
  RechargesActionsDownloadRechargeClick = 'recharge:actionsDownloadRecharge_click',
  RechargesListActionCloneRechargeClick = 'recharges:list_action_clone_recharge_click',
  RechargesActionsOpenInvoiceClick = 'recharges:actionsOpenInvoice_click',
  RechargesActionsDetailsClick = 'recharges:actions_details_click',
  RechargesDetailsActionOpenBillClick = 'recharges:details_action_open_bill_click',
  RechargesDetailsActionDownloadDetailedRechargeClick = 'recharges:details_action_download_detailed_recharge_click',
  RechargesSummaryDownloadRechargeClick = 'recharges:summaryDownloadRecharge_click',
  RechargesDetailsActionCloneRechargeClick = 'recharges:details_action_clone_recharge_click',
  RechargesDetailsActionGeneratePixClick = 'recharges:details_action_generate_pix_click',
  RechargesDetailsActionCancelClick = 'recharges:details_action_cancel_click',
  RechargesActionsTryScheduleAgainClick = 'recharges:actions_try_schedule_again_click',
  RechargesPaymentConfigScreenView = 'recharges:payment_config_screen_view',
  RechargesUnvalidDistributionBackView = 'recharges:unvalid_distribution_back_view',
  RechargesSaveAndContinueLaterView = 'recharges:save_and_continue_later_view',
  RechargesPaymentConfigurationContinueLaterClick = 'recharges:payment_configuration_continue_later_click',
  RechargesIncludeEmployeeClick = 'recharges:includeEmployee_click',
  RechargesSummaryScheduleView = 'recharges:summary_schedule_view',
  RechargesSummaryView = 'recharges:summary_view',
  RechargesScheduleLoadingScreenView = 'recharges:scheduleLoadingScreen_view',
  RechargesScheduledConfirmationBackToRechargesClick = 'recharges:scheduled_confirmation_back_to_recharges_click',
  RechargesDetailsView = 'recharges:details_view',
  RechargesScheduledForTodayConfirmationView = 'recharges:scheduled_for_today_confirmation_view',
  RechargesScheduledConfirmationView = 'recharges:scheduled_confirmation_view',
  RechargesScheduledBackToRechargesClick = 'recharges:scheduled_back_to_recharges_click',
  RechargesSummaryConfirmAndGenerateInvoiceClick = 'recharges:summary_confirm_and_generate_invoice_click',
  RechargesInvoiceScreenView = 'recharges:invoiceScreen_view',
  RechargesLoadingWaitingFromScheduleScreenView = 'recharges:loadingWaitingFromScheduleScreen_view',
  RechargesBackToMainFromInvoiceScreenView = 'recharge:backToMainFromInvoiceScreen_view',
  RechargesListCodeBarCopyClick = 'recharges:listCodeBarCopy_Click',
  RechargesListInvoiceScreenView = 'recharges:listInvoiceScreen_view',
  RechargesListBackToMainFromInvoiceScreenClick = 'recharges:listBackToMainFromInvoiceScreen_click',
  RechargesListDownloadInvoicePdfClick = 'recharges:listDownloadInvoicePdf_click',
  RechargesFailInvoiceScreenBackToMainClick = 'recharges:FailInvoiceScreenBackToMain_click',
  RechargesErrorInvoiceScreenView = 'recharges:error_InvoiceScreen_view',
  RechargesFailToScheduleTryAgainClick = 'recharges:failToScheduleTryAgain_click',
  RechargeFailParticipantSelectionScreenView = 'recharge:failParticipantSelectionScreen_view',
  RechargeFailEmployeesScreenView = 'recharge:failEmployeesScreen_view',
  RechargesPixScreenView = 'recharges:pixScreen_view',
  RechargesCodePixCopyClick = 'recharges:codePixCopy_Click',
  RechargesErrorGeneratingPixView = 'recharges:errorGeneratingPix_view',
  RechargesErrorGeneratingPixTryAgainClick = 'recharges:errorGeneratingPixTryAgain_click',
  RechargesActionsOpenPixClick = 'recharges:actions_open_pix_click',
  RechargesListCodePixScreenView = 'recharges:listCodePixScreen_view',
  RechargesListCodePixCopyClick = 'recharges:listCodePixCopy_Click',
  RechargesListFailIGeneratePixUpdatePageClick = 'recharges:listFailIGeneratePixUpdatePage_Click',
  RechargeDistributeNow = 'recharge:distributeNow_click',
  RechargeFilterEmployeesParticipantsZeroBalanceClick = 'recharges:filter_employees_participants_zero_balance_click',
  RechargeStayOnParticipantsPageClick = 'recharges:stay_on_participants_page_click',
  RechargesGoToPaymentConfigClick = 'recharges:go_to_payment_config_click',
  RechargesChristmasCampaignBannerClick = 'recharges:christmas_campaign_banner_click',
  RechargesChristmasCampaignProductActivationModalView = 'recharges:christmas_campaign_product_activation_modal_view',
  RechargesBannerHRToolsTestInformContactDataClick = 'banner:hrToolsTestInformContactData_click',
  RechargesBannerHRToolsTestClick = 'banner:hrToolsTest_click',
  RechargesBannerHRToolsTestView = 'banner:hrToolsTest_view',
  RechargesParticipantSelectionPageView = 'recharges:participant_selection_page_view',
  RechargesInsertParticipantClick = 'recharges:insert_participants_current_recharge_click',
  RechargesRemoveParticipantClick = 'recharges:remove_participants_current_recharge_click',
  RechargesContinueParticipantClick = 'recharges:go_to_amount_config_click',
  RechargesParticipantValuesPageView = 'recharges:amount_config_screen_view',
  RechargesParticipantValuesErrorPageView = 'recharges:failure_amount_config_screen_view',
  RechargesContinueParticipantValueClick = 'recharges:new_go_to_payment_config_click',
  RechargesParticipantWithZeroValuesPageView = 'recharges:amount_zero_values_view',
  RechargesParticipantWithMinValuesPageView = 'recharges:amount_block_min_value_view',
  RechargesContinuePaymentConfigClick = 'recharges:go_to_summary_click',
  RechargesPaymentConfigPageView = 'recharges:new_payment_config_screen_view',
  RechargesSummaryPageView = 'recharges:new_summary_view',
  RechargesSummaryConfirmClick = 'recharges:summary_confirm_recharge_click',
  RechargesSummaryDownloadClick = 'recharges:summary_download_click',
  RechargesScheduledSuccessPageView = 'recharges:scheduled_success_recharge_view',
  RechargesCopyPixCodeClick = 'recharges:copy_pix_code_click',
  RechargesDownloadBankSlipClick = 'recharges:download_bank_slip_click',
  RechargesCopyBarCodeClick = 'recharges:copy_bar_code_click',
  RechargesGoToHomeClick = 'recharges:go_to_home_click_from_success_page',
  RechargesGoToDetailsClick = 'recharges:go_to_details_click_from_success_page',
  RechargesContinueLaterClick = 'recharges:continue_later_click',
  RechargesHomeListPageView = 'recharges:home_list_page_view',
  RechargesSelectOptionsPageView = 'recharges:select_options_page_view',
  RechargesSelectBenefitsPageView = 'recharges:select_benefits_page_view',
  RechargesDetailsPageView = 'recharges:details_page_view',
  RechargesCreateRechargeNewClick = 'recharges:create_recharge_new_click',
  RechargesFilterHomeClick = 'recharges:filter_home_click',
  RechargesCopyShortIdClick = 'recharges:copy_short_id_click',
  RechargesTableActionsClick = 'recharges:table_actions_click',
  RechargesContinueSelectOptionsClick = 'recharges:go_to_benefits_selection_click',
  RechargesContinueSelectBenefitsClick = 'recharges:go_to_participants_selection_click',
  RechargesDetailCopyShortIdClick = 'recharges:detail_copy_short_id_click',
  RechargesDetailActionClick = 'recharges:detail_action_click',
  // chargeback click events
  ChargebackCreateNewClick = 'chargeback:create_new_click',
  ChargebackListFilterClick = 'chargeback:list_filter_click',
  ChargebackDetailsFilterClick = 'chargeback:details_filter_click',
  ChargebackProductSelectClick = 'chargeback:product_selected_continue_click',
  ChargebackAddedParticipantsClick = 'chargeback:added_participants_click',
  ChargebackRemoveParticipantsClick = 'chargeback:remove_participants_click',
  ChargebackParticipantsWithValueClick = 'chargeback:added_participants_values_click',
  ChargebackContinueClick = 'chargeback:list_of_employees_continue_click',
  ChargebackConfirmationClick = 'chargeback:create_confirm_click',
  ChargebackOpenTermsOfUseClick = 'chargeback:open_terms_of_use_click',
  // chargeback view events
  ChargebackListView = 'chargeback:list_view',
  ChargebackDetailsView = 'chargeback:details_view',
  ChargebackProductSelectView = 'chargeback:product_selected_view',
  ChargebackAddParticipantsView = 'chargeback:add_participants_view',
  ChargebackAddParticipantsValuesView = 'chargeback:add_participants_values_view',
  ChargebackConfirmationView = 'chargeback:confirmation_view',
  ChargebackModalConfirmationView = 'chargeback:modal_confirmation_view',
  ChargebackSuccessView = 'chargeback:success_view',
}

export type RechargeEventsType = typeof RechargeEvents;

export type RechargeEventType = keyof RechargeEventsType;

export type RechargeEventTypeCollection = RechargeEventType[];

const RechargeEventKeys = Object.keys(RechargeEvents) as RechargeEventTypeCollection;

export const recharge: EventsDictionary = RechargeEventKeys.reduce((collection, eventKey) => {
  const eventName = RechargeEvents[eventKey];

  return {
    ...collection,
    [eventName]: <RechargeEventParams>(params: EventParamsType<RechargeEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
