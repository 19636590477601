import { EventParamsType, EventsDictionary } from '../types';

export enum HeaderEvents {
  HeaderLogoClick = 'Header:Logo_Click',
  HeaderNotificationClick = 'Header:Notification_Click',
  HeaderHelpClick = 'Header:Help_Click',
  HeaderSwitchLicenseClick = 'Header:SwitchLicense_Click',
  HeaderProfileClick = 'Header:Profile_Click',
  HeaderLogoutClick = 'Header:Logout_Click',
}

export type HeaderEventsType = typeof HeaderEvents;

export type HeaderEventType = keyof HeaderEventsType;

export type HeaderEventTypeCollection = HeaderEventType[];

const HeaderEventKeys = Object.keys(HeaderEvents) as HeaderEventTypeCollection;

export const header: EventsDictionary = HeaderEventKeys.reduce((collection, eventKey) => {
  const eventName = HeaderEvents[eventKey];

  return {
    ...collection,
    [eventName]: <HeaderEventParams>(params: EventParamsType<HeaderEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
