import { Animations } from '@benefits/crafts';

import { Wrapper } from './Loading.styles';

export interface LoadingProps {
  size?: number;
}

const Loading: React.FC<LoadingProps> = ({ size = 200, ...props }) => (
  <Wrapper {...props} data-testid="loading-fruits">
    <Animations.CardsLoading height={size} width={size} />
  </Wrapper>
);

export { Loading };
