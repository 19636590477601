import { Text } from '@benefits/components';
import styled, { css } from 'styled-components';

export interface MenuButtonItemProps {
  active?: boolean;
}

export const Button = styled.button<MenuButtonItemProps>`
  background-color: transparent;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 0.7rem;
  margin: 0px;
  cursor: pointer;
  transition: 200ms all;
  :hover {
    background-color: #f5f5f5;
    & p {
      font-weight: 700;
    }
  }
  &::before {
    transition: 0.5s ease;
    content: '';
    position: absolute;
    left: 0px;
    height: 50%;
    border-left: 4px solid #3d3d3d;
    border-radius: 0px 10px 10px 0px;
    opacity: 0;
    visibility: hidden;
  }

  ${(props) =>
    props.active &&
    css`
      &::before {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

export const Icon = styled.img`
  margin-right: 10px;
  margin-left: 5px;
`;

export const Title = styled(Text)`
  color: #666666;
  font-size: 14px;
  white-space: nowrap;
`;
