import type { EventParamsType, EventsDictionary } from '../types';

export enum NewsEvents {
  MAIN_NEWS_CLICK = 'Main_news_click',
  NEWS_PAGEVIEW = 'news_pageview',
  VIDEO_CLICK = 'ad:WatchTutorial_click',
  SEE_LATER_CLICK = 'ad:SeeLater_click',
  SEE_MORE_CLICK = 'ad:MainCTA_click',
  CLOSE_TUTORIAL_CLICK = 'ad:CloseTutorial_click',
  PAGE_VIEW = 'ad:View',
  NEW_RECHARGE_NEW_EXP_TRAINING_CLICK = 'ad:NewRechargeNewExpTraining_click',
  NEW_BENEFITS_SEE_LATER_CLICK = 'ad:Newbenefits_seeLater_click',
  NEW_BENEFITS_SEE_MORE_CLICK = 'ad:Newbenefits_seeMore_click',
  NEW_BENEFITS_PAGE_VIEW = 'ad:Newbenefits_view',
  NEW_BENEFITS_SUCCESS_PAGE_VIEW = 'ad:Newbenefits_success_view',
  NEW_REWARD_BENEFIT_SEE_LATER_CLICK = 'ad:RewardBenefit_seeLater_click',
  NEW_REWARD_BENEFIT_SEE_MORE_CLICK = 'ad:RewardBenefit_seeMore_click',
  NEW_REWARD_BENEFIT_PAGE_VIEW = 'ad:RewardBenefit_view',
  NEW_REWARD_BENEFIT_SUCCESS_PAGE_VIEW = 'ad:RewardBenefit_success_view',
  NEW_PAYROLL_LOANS_PAGE_VIEW = 'ad:poc_PayrollLoans_view',
  NEW_PAYROLL_LOANS_SUCCESS_PAGE_VIEW = 'ad:poc_PayrollLoans_success_view',
  NEW_PAYROLL_LOANS_SEE_LATER_CLICK = 'ad:poc_PayrollLoans_seeLater_click',
  NEW_PAYROLL_LOANS_SEE_MORE_CLICK = 'ad:poc_PayrollLoans_seeMore_click',
  COLAB_MAIS_THREE_MONTHS_FREE_VIEW = 'banner:COLAB_MAIS_THREE_MONTHS_FREE_VIEW',
  COLAB_MAIS_THREE_MONTHS_FREE_SEE_LATER_CLICK = 'banner:COLAB_MAIS_THREE_MONTHS_FREE_SEE_LATER_CLICK',
  COLAB_MAIS_THREE_MONTHS_FREE_MORE_CLICK = 'banner:COLAB_MAIS_THREE_MONTHS_FREE_MORE_CLICK',
  OnboardingPageView = 'onboarding:valuemodal_view',
  OnboardingClick = 'onboarding:valuemodal_click',
  RechargesBannerHRToolsTestClick = 'banner:hrToolsTest_click',
}

export type NewsEventsType = typeof NewsEvents;

export type NewsEventType = keyof NewsEventsType;

export type NewsEventTypeCollection = NewsEventType[];

const NEWS_EVENT_KEYS = Object.keys(NewsEvents) as NewsEventTypeCollection;

export const news: EventsDictionary = NEWS_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = NewsEvents[eventKey];

  return {
    ...collection,
    [eventName]: <NEWSEventParams>(params: EventParamsType<NEWSEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
