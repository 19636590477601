import { Header } from '@benefits/components';
import { LogoIfoodBeneficios, LogoIfoodBeneficiosShort } from '@benefits/crafts';
import { PropsWithChildren } from 'react';

import { Drawer } from '../../../Drawer/Drawer';
import { BaseLayout, BaseLayoutProps } from '../../BaseLayout';
import { FlexContainer } from './WithWave.styles';

export const WithWaveLayout: React.FC<PropsWithChildren<BaseLayoutProps>> = ({ children, ...baseProps }) => (
  <BaseLayout {...baseProps}>
    <Header
      headerLogo={<LogoIfoodBeneficios.IfoodBeneficiosSVG />}
      headerMobileLogo={<LogoIfoodBeneficiosShort.IfoodBeneficiosShortSVG />}
    />
    <Drawer isStepper />
    <FlexContainer id="withwave">{children}</FlexContainer>
  </BaseLayout>
);
