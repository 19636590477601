import { ThemeSpacingKey, ThemeSpacingType } from '@ifood/fits-themes';
import styled, { css } from 'styled-components';
type PositionType = 'top' | 'bottom' | 'left' | 'right';

export const prefix = 'spacing';
export const positions: PositionType[] = ['top', 'bottom', 'left', 'right'];

export const getProperty = (position: PositionType, value: string, kind: string): string => {
  return `.${prefix}-${position}-${kind} {margin-${position}: ${value};}`;
};

const getSpacingsStyle = (spacings: ThemeSpacingType, position: PositionType) => {
  const spacingKinds = Object.keys(spacings) as ThemeSpacingKey[];

  return spacingKinds.map((spacing) => {
    const [firstLetter, ...rest] = spacing.replace(prefix, '').split('');
    const kind = firstLetter.toLowerCase() + rest.join('');
    const value = spacings[spacing];

    return css`
      ${getProperty(position, value, kind)};
    `;
  });
};

const SpacingProvider = styled.div`
  ${({ theme }) => {
    return positions.map((position) => getSpacingsStyle(theme.spacing, position));
  }}
`;

export { SpacingProvider };
