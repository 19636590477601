import { Icons } from '@benefits/crafts';
import { Text } from '@ifood/fits-components';

import type { StepProps } from '../Stepper.types';
import { Container, CurrentStepIcon, Description, iconColors } from './Step.styles';

const iconVariant = {
  completed: Icons.CheckmarkFilled,
  current: Icons.CircleFilled,
  next: Icons.CircleFilled,
};

export const Step: React.FC<StepProps> = ({ variant, text }) => {
  const Icon = iconVariant[variant];

  return (
    <Container>
      <Icon iconColor={iconColors[variant]} size="sm" />
      <Text>
        <Description variant={variant}>{text}</Description>
      </Text>
      {variant === 'current' && (
        <CurrentStepIcon>
          <Icons.ShineFilled iconColor="#E7A74E" />
        </CurrentStepIcon>
      )}
    </Container>
  );
};
