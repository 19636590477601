import AdminSelectedIcon from './assets/admins-selected.svg';
import AdminIcon from './assets/admins.svg';
import CardsSelectedIcon from './assets/cards-selected.svg';
import CardsIcon from './assets/cards.svg';
import EmployeeSelectedIcon from './assets/colaboradores-selected.svg';
import EmployeeIcon from './assets/colaboradores.svg';
import CompaniesSelectedIcon from './assets/companies-selected.svg';
import CompaniesIcon from './assets/companies.svg';
import CollectiveAgreementSelected from './assets/convencaoColective-selected.svg';
import CollectiveAgreement from './assets/convencaoColetiva.svg';
import FinancialSelectedIcon from './assets/financial-selected.svg';
import FinancialIcon from './assets/financial.svg';
import HomeSelectedIcon from './assets/home-selected.svg';
import HomeIcon from './assets/home.svg';
import IfoodCardSelectedIcon from './assets/ifoodcard-selected.svg';
import IfoodCardIcon from './assets/ifoodcard.svg';
import MarketplaceSelectedIcon from './assets/marketplace-selected.svg';
import MarketplaceIcon from './assets/marketplace.svg';
import RechargeSelectedIcon from './assets/recharges-selected.svg';
import RechargeIcon from './assets/recharges.svg';
import ReportSelectedIcon from './assets/report-selected.svg';
import ReportIcon from './assets/report.svg';

export type MenuIconType = {
  normal: string;
  selected: string;
};

export const MenuIcons = {
  dashboard: {
    normal: HomeIcon,
    selected: HomeSelectedIcon,
  },
  marketplace: {
    normal: MarketplaceIcon,
    selected: MarketplaceSelectedIcon,
  },
  recharge: {
    normal: RechargeIcon,
    selected: RechargeSelectedIcon,
  },
  employee: {
    normal: EmployeeIcon,
    selected: EmployeeSelectedIcon,
  },
  cards: {
    normal: CardsIcon,
    selected: CardsSelectedIcon,
  },
  financial: {
    normal: FinancialIcon,
    selected: FinancialSelectedIcon,
  },
  report: {
    normal: ReportIcon,
    selected: ReportSelectedIcon,
  },
  admin: {
    normal: AdminIcon,
    selected: AdminSelectedIcon,
  },
  company: {
    normal: CompaniesIcon,
    selected: CompaniesSelectedIcon,
  },
  collectiveAgreement: {
    normal: CollectiveAgreement,
    selected: CollectiveAgreementSelected,
  },
  ifoodCard: {
    normal: IfoodCardIcon,
    selected: IfoodCardSelectedIcon,
  },
};
