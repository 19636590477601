import { UserContext, useLocalStorage } from '@benefits/common-services';
import { useCallback, useContext, useMemo } from 'react';

import { NewsStorageType } from './useNews.types';

const NEWS_KEY = '__ifood_news_banner__';

export const useNews = () => {
  const context = useContext(UserContext);
  const currentUserId = context?.state?.user?.userId;

  const [value, setter] = useLocalStorage(NEWS_KEY, JSON.stringify([]));

  const newsStorage = useMemo(() => (JSON.parse(value) as NewsStorageType[]) ?? [], [value]);

  const hasNews = useCallback(
    (newsRoute: string) => {
      const userNewsStorage = newsStorage?.find((item) => item.userId === currentUserId);
      const hasNewsClosed = userNewsStorage?.routes?.some((route) => route === newsRoute);
      const hasRouteNews = !hasNewsClosed || !userNewsStorage;

      return hasRouteNews;
    },
    [newsStorage, currentUserId]
  );

  const read = (route: string) => {
    const data = (JSON.parse(value) as NewsStorageType[]) ?? [];
    const userFound = newsStorage?.find((item) => item.userId === currentUserId);

    if (userFound) {
      const userIndex = data?.findIndex((item) => item.userId === currentUserId);
      const userRoutes = userFound.routes.filter((item) => item !== route);
      data[userIndex].routes = [...userRoutes, route];
      return setter(JSON.stringify([...data]));
    }

    if (!userFound) {
      setter(
        JSON.stringify([
          ...data,
          {
            userId: currentUserId,
            routes: [route],
          },
        ])
      );
    }
  };

  return {
    hasNews,
    read,
  };
};
