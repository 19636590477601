import { LineHeightsTypeMap } from './lineHeights.types';

export const lineHeights: LineHeightsTypeMap<string> = {
  xxl: '3rem',
  xl: '2.5rem',
  l: '2rem',
  ml: '1.75rem',
  m: '1.5rem',
  s: '1.375rem',
  xs: '1.25rem',
};
