import { Container } from '@benefits/components';
import { LogoIfoodBeneficios } from '@benefits/crafts';
import { PropsWithChildren } from 'react';

import { BaseLayout } from '../BaseLayout';
import { Header, Rectangles } from './CraftLayout.styles';

export const CraftLayout: React.FC<PropsWithChildren> = ({ children, ...props }) => {
  return (
    <BaseLayout
      renderHeader={() => (
        <Header>
          <Container>
            <LogoIfoodBeneficios.IfoodBeneficiosSVG height={36} width={160} />
          </Container>

          <Rectangles />
        </Header>
      )}
      {...props}
    >
      <Container>{children}</Container>
    </BaseLayout>
  );
};
