import { useContext } from 'react';

import { INVOICED_BOLETO } from '../../store/enabled-features';
import { FeatureContext } from '../../store/FeatureStore';
import * as featureToggles from '../../toggle/featureToggles';
import { ToggleContext } from '../../toggle/ToggleStore';
import { UseFinancialNXP, UseFinancialNXPReturn } from './useFinancialNXP.types';

export const useFinancialNXP = ({ groupId }: UseFinancialNXP): UseFinancialNXPReturn => {
  const featureFlagContext = useContext(FeatureContext);
  const featureTogglesContent = useContext(ToggleContext);

  const checkGroupIdIsEnable = (groupId: string) =>
    featureTogglesContent?.belongsTo(featureToggles.FINANCIAL_NXP_ENABLED_BY_GROUP_ID, groupId);

  const isFinancialNxpToggleEnabled = Boolean(featureTogglesContent?.isEnable(featureToggles.FINANCIAL_NXP_ENABLED));

  const isGroupIdEnabled = !isFinancialNxpToggleEnabled && groupId && checkGroupIdIsEnable(groupId);

  const isPostPaid = Boolean(featureFlagContext?.isEnable(INVOICED_BOLETO));
  const isPrePaid = !isPostPaid;

  const isFinancialNxpEnabled = isPrePaid || ((isGroupIdEnabled || isFinancialNxpToggleEnabled) && isPostPaid);

  return {
    isFinancialNxpEnabled,
    isLoading: featureTogglesContent?.isFetching || featureFlagContext?.state?.isLoading,
    isSuccess: featureTogglesContent?.isSuccess && featureFlagContext?.state?.isSuccess,
  };
};
