import Link from 'next/link';
import React, { PropsWithChildren } from 'react';

import { Item, List } from './Breadcrumbs.styles';

type ItemType = {
  label: string;
  url: string;
};

export interface BreadcrumbsProps extends React.HTMLAttributes<HTMLUListElement> {
  items: ItemType[];
}

const Breadcrumbs: React.FC<PropsWithChildren<BreadcrumbsProps>> = ({ items, ...props }) => {
  const mapper = items.map(({ label, url }) => (
    <Item key={url}>
      <Link passHref href={url}>
        {label}
      </Link>
    </Item>
  ));

  return <List {...props}>{mapper}</List>;
};

export { Breadcrumbs };
