import { ToggleContext } from '@benefits/features';
import { useContext } from 'react';

import { UserContext } from '../../stores/UserStore';

export const useMarketplacePublicCompany = () => {
  const context = useContext(UserContext);
  const user = context?.state?.user;
  const groupId = user?.group?.id;
  const featureTogglesContent = useContext(ToggleContext);
  const enabled = featureTogglesContent?.belongsTo('MARKETPLACE_PUBLIC_COMPANY', groupId as string);
  const requestSuccess = featureTogglesContent?.isSuccess;
  return requestSuccess && enabled;
};
