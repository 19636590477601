import { Text } from '@benefits/components';
import { Illustrations } from '@benefits/crafts';

import { Container } from './EmptySearch.styles';

type EmptySearchProps = React.HTMLAttributes<HTMLElement>;

const EmptySearch: React.FC<EmptySearchProps> = (props) => {
  return (
    <Container {...props}>
      <Illustrations.EmptySearch />
      <Text component="figcaption">Não encontramos nenhum resultado para sua busca.</Text>
    </Container>
  );
};

export { EmptySearch };
