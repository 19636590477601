import { Illustrations } from '@benefits/crafts';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.spacingLg} auto;
  text-align: center;

  & > p {
    margin-top: ${({ theme }) => theme.spacing.spacing4xs};
  }

  & > button {
    margin-top: ${({ theme }) => theme.spacing.spacingMd};
  }
`;

export const Illustration = styled(Illustrations.Unexpected)`
  margin-bottom: ${({ theme }) => theme.spacing.spacing2xs};
`;
