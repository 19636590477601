import { EventParamsType, EventsDictionary } from '../types';

export enum NotificationEvents {
  NotificationDrawerView = 'Notification:Drawer_View',
  NotificationDrawerCallback = 'Notification:Drawer_Callback',
  NotificationDrawerClick = 'Notification:Drawer_Click',
  NotificationDrawerCloseClick = 'Notification:Drawer_Close_Click',
  NotificationCardClick = 'Notification:Card_Click',
}

export type NotificationEventsType = typeof NotificationEvents;

export type NotificationEventType = keyof NotificationEventsType;

export type NotificationEventTypeCollection = NotificationEventType[];

const NotificationEventKeys = Object.keys(NotificationEvents) as NotificationEventTypeCollection;

export const notification: EventsDictionary = NotificationEventKeys.reduce((collection, eventKey) => {
  const eventName = NotificationEvents[eventKey];

  return {
    ...collection,
    [eventName]: <NotificationEventParams>(params: EventParamsType<NotificationEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
