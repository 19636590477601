import { AmplitudePlugin } from '@ifood/aboyeur-plugin-amplitude';
import { FasterPlugin } from '@ifood/aboyeur-plugin-faster';
import { GoogleAnalyticsPlugin } from '@ifood/aboyeur-plugin-ga';

export const PLUGINS = {
  [FasterPlugin.displayName]: FasterPlugin,
  [AmplitudePlugin.displayName]: AmplitudePlugin,
  [GoogleAnalyticsPlugin.displayName]: GoogleAnalyticsPlugin,
};

export const PLUGIN_NAMES = Object.keys(PLUGINS);
