export { defaultTheme as fitsTheme } from '@ifood/fits-themes';

import { breakpoints } from './src/breakpoints/breakpoints';
import { colors } from './src/colors/colors';
import { fonts } from './src/fonts/fonts';
import { fontSizes } from './src/fontSizes/fontSizes';
import { iconSizes } from './src/iconSizes/iconSizes';
import { lineHeights } from './src/lineHeights/lineHeights';
import { spaces } from './src/spaces/spaces';

export const theme = {
  colors,
  breakpoints,
  fonts,
  fontSizes,
  iconSizes,
  lineHeights,
  spaces,
};

export type { IconSizesType, IconSizesTypeMap } from './src/iconSizes/iconSizes.types';
export type { BreakpointsType } from './src/breakpoints/breakpoints.types';
export type { ColorsType } from './src/colors/colors.types';
export type { FontsType, FontsTypeMap } from './src/fonts/fonts.types';
export type { FontSizesType, FontSizesTypeMap } from './src/fontSizes/fontSizes.types';
export type { LineHeightsType, LineHeightsTypeMap } from './src/lineHeights/lineHeights.types';
export type { SpacesType, SpacesTypeMap } from './src/spaces/spaces.types';
