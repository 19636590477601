import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 80px 0 40px;

  svg {
    z-index: 2;
  }
`;

const Rectangle01Style = `
  content: '';
  display: block;
  border: 2px solid #69022d;
  position: absolute;
  border-radius: 36px;
  transform: skewY(7deg);
  rotate: 90deg;
  z-index: 1;
`;

const Rectangle02Style = `
  content: '';
  display: block;
  background-color: #91f5cd;
  border-radius: 36px;
  transform: skewY(7deg);
  rotate: 90deg;
  z-index: 0;
`;

export const Rectangles = styled.div`
  position: absolute;
  right: -200px;
  bottom: 80px;
  z-index: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointSmMaxWidth}) {
    right: -230px;
  }

  &:before {
    ${Rectangle01Style}
    width: 340px;
    height: 270px;
    bottom: -40px;
    right: -40px;
  }

  &:after {
    ${Rectangle02Style}
    width: 400px;
    height: 320px;
  }
`;
