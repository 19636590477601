import { UserContext } from '@benefits/common-services';
import { isServer } from '@benefits/common-utils';
import { useContext, useMemo } from 'react';

import { eventDispatcher } from '../client';
import { HelpEvents, HelpsEventType, HelpsEventTypeCollection } from './help';

export type SendReportEventType = (eventType: HelpsEventTypeCollection, data?: any) => void;

export const useHelpDispatcher = () => {
  const context = useContext(UserContext);
  const user = context?.state?.user;
  const dispatcher = isServer() ? undefined : eventDispatcher.events['help'];

  const SessionData = useMemo(
    () => ({
      UserID: user?.userId,
      GroupID: user?.group?.id,
    }),
    [user]
  );

  const sendEvent = <HelpEventDataType>(eventName: HelpsEventType, data?: HelpEventDataType) => {
    if (dispatcher) {
      const eventFunction = dispatcher[HelpEvents[eventName]];

      if (eventFunction) {
        eventFunction({
          ...data,
          ...SessionData,
        });
      }
    }
  };

  return {
    sendEvent,
  };
};
