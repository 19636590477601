import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-weight: 500;

  & > li:not(:last-child):after {
    margin: 0 2px;
    color: #a6a6a6;
    content: '/';
  }

  & > li:last-child a {
    color: #717171;
    pointer-events: none;
    font-weight: 600;
  }

  margin-bottom: 16px;
`;

export const Item = styled.li`
  display: inline;

  & > a {
    text-decoration: none;

    color: #a6a6a6;
  }
`;
