import { UserContext } from '@benefits/common-services';
import { isServer } from '@benefits/common-utils';
import { Button, CloseButton, Dialog, DialogActions, DialogContent, DialogHeader } from '@benefits/components';
import { NewsEvents, eventDispatcher } from '@benefits/tracking';
import { useContext, useMemo, useRef } from 'react';
import YouTube from 'react-youtube';

export interface NewsDialogProps {
  isOpen: boolean;
  toggle: () => void;
  callback: () => void;
  seeLater: () => void;
  title: string;
  videoId: string;
  closeToHomeTitleButton: string;
  actionTitleButton: string;
  banner: string;
  newsTheme: string;
}

export const NewsDialog: React.FC<NewsDialogProps> = ({
  isOpen,
  toggle,
  callback,
  seeLater,
  title,
  actionTitleButton,
  closeToHomeTitleButton,
  videoId,
  newsTheme,
}) => {
  const context = useContext(UserContext);
  const user = context?.state?.user;
  const videoRef = useRef(null);

  const sessionData = useMemo(
    () => ({
      UserID: user?.userId,
      GroupID: user?.group?.id,
    }),
    [user]
  );

  const dispatcher = isServer() ? undefined : eventDispatcher.events.news;

  const dispatchEvent = (event: string) => {
    if (dispatcher) {
      const eventFunction = dispatcher[event];

      if (eventFunction) {
        eventFunction({
          ...sessionData,
          newsTheme,
        });
      }
    }
  };

  const close = () => {
    const video = videoRef.current as any;

    video?.resetPlayer();
    toggle();

    dispatchEvent(NewsEvents.CLOSE_TUTORIAL_CLICK);
  };

  const onPlay = () => dispatchEvent(NewsEvents.VIDEO_CLICK);

  return (
    <Dialog aria-labelledby="Remover participantes" open={isOpen} title="Como emitir cartões?">
      <DialogHeader
        endAdornment={<CloseButton aria-label="Fechar" data-testid="button-close" onClick={close} />}
        id="dialog-title"
      >
        {title}
      </DialogHeader>
      <DialogContent id="dialog-description">
        <YouTube opts={{ height: '450', width: '800' }} ref={videoRef} videoId={videoId} onPlay={onPlay} />
      </DialogContent>
      <DialogActions>
        <Button shapeStyle="pill" type="button" variant="tertiary" onClick={seeLater}>
          {closeToHomeTitleButton}
        </Button>
        <Button shapeStyle="pill" type="button" onClick={callback}>
          {actionTitleButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
