import { IconButton, NavBar as NavBarDS } from '@ifood/fits-components';
import styled from 'styled-components';

export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const NavBar = styled(NavBarDS)`
  border-color: #f0f0f0;
`;

export const ButtonLogo = styled(IconButton)<{ hasMobileLogo?: boolean }>`
  :hover:before {
    background-color: transparent;
  }

  :hover:after {
    background-color: transparent;
  }

  .desktopLogo {
    display: flex;
  }

  .mobileLogo {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointSmMaxWidth}) {
    .mobileLogo {
      display: ${({ hasMobileLogo }) => (hasMobileLogo ? 'flex' : 'none')};
    }

    .desktopLogo {
      display: ${({ hasMobileLogo }) => (hasMobileLogo ? 'none' : 'flex')};
    }
  }
`;
