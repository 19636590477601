import '@ifood/fonts';

import { GlobalStyle } from '@ifood/fits-components';
import type { PropsWithChildren } from 'react';
import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

html {
  scroll-behavior: smooth;
*,
::after,
::before {
  box-sizing: border-box
}
}
`;

const AppLayout: React.FC<PropsWithChildren> = (props) => (
  <>
    <Global />
    <GlobalStyle {...props} />
  </>
);

export { AppLayout };
