import type { EventParamsType, EventsDictionary } from '../types';

export enum OnboardingEvents {
  OnboardingWelcomeFirstSplashView = 'Onboarding:Welcome_FirstSplash_View',
  OnboardingWelcomeSecondSplashView = 'Onboarding:Welcome_SecondSplash_View',
  OnboardingWelcomeFirstStepView = 'Onboarding:Welcome_FirstStep_View',
  OnboardingWelcomeSecondStepView = 'Onboarding:Welcome_SecondStep_View',
  OnboardingWelcomeThirdStepView = 'Onboarding:Welcome_ThirdStep_View',
  OnboardingWelcomeDialogMobileView = 'Onboarding:_Welcome_DialogMobile_View',
  OnboardingWelcomeSkipClick = 'Onboarding:Welcome_Skip_Click',
  OnboardingWelcomeStepsClick = 'Onboarding:Welcome_Steps_Click',
  OnboardingWelcomeFirstStepContinueClick = 'Onboarding:Welcome_FirstStep_Continue_Click',
  OnboardingWelcomeFirstStepBackClick = 'Onboarding:Welcome_FirstStep_Back_Click',
  OnboardingWelcomeFirstStepSkipClick = 'Onboarding:Welcome_FirstStep_Skip_Click',
  OnboardingWelcomeSecondStepBackClick = 'Onboarding:Welcome_SecondStep_Back_Click',
  OnboardingWelcomeSecondStepContinueClick = 'Onboarding:Welcome_SecondStep_Continue_Click',
  OnboardingWelcomeSecondStepSkipClick = 'Onboarding:Welcome_SecondStep_Skip_Click',
  OnboardingWelcomeThirdStepBackClick = 'Onboarding:Welcome_ThirdStep_Back_Click',
  OnboardingWelcomeThirdStepContinueClick = 'Onboarding:Welcome_ThirdStep_Continue_Click',
  OnboardingWelcomeDialogMobileContinueClick = 'Onboarding:Welcome_DialogMobile_Continue_Click',
}

export type OnboardingEventsType = typeof OnboardingEvents;

export type OnboardingEventType = keyof OnboardingEventsType;

export type OnboardingEventTypeCollection = OnboardingEventType[];

const ONBOARDING_EVENT_KEYS = Object.keys(OnboardingEvents) as OnboardingEventTypeCollection;

export const onboarding: EventsDictionary = ONBOARDING_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = OnboardingEvents[eventKey];

  return {
    ...collection,
    [eventName]: <OnboardingEventParams>(params: EventParamsType<OnboardingEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
