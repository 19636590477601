import styled, { css } from 'styled-components';
export const Container = styled.div`
  min-height: calc(100vh - 56px);
  width: 60px;
  z-index: 3;
  position: fixed;
`;

interface ContentProps {
  isStepper?: boolean;
  collapsed?: boolean;
}

export const Content = styled.div<ContentProps>`
  position: fixed;
  width: 60px;
  padding: 20px 0px;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #f0f0f0;
  -webkit-transition: all 100ms ease;
  background-color: white;
  & p {
    opacity: 0;
    visibility: none;
    pointer-events: none;
    transition: opacity 100ms ease;
  }

  ${(props) =>
    props.collapsed &&
    css`
      filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.09));
      width: 177px;
      & p {
        opacity: 1;
        pointer-events: all;
        transition: opacity 100ms ease;
        visibility: visible;
      }
    `}

  ${(props) =>
    !props.collapsed &&
    css`
      &:hover {
        filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.09));
        width: 177px;
        & p {
          opacity: 1;
          pointer-events: all;
          transition: opacity 100ms ease;
          visibility: visible;
        }
      }
    `}

  ${(props) =>
    props.isStepper &&
    css`
      margin-top: 56px;
    `}
  ${(props) =>
    !props.isStepper &&
    css`
      top: ${({ theme }) => theme.component.navBar.root.minHeight};
    `}
`;

export interface MenuButtonItemProps {
  active?: boolean;
}

export const MenuItem = styled.li`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const MenuContainer = styled.ul`
  padding: 0px;
  margin: 0px;
`;

export const Footer = styled.div``;
