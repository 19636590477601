import { isDevelopment } from '@benefits/common-utils';

type ActionType =
  | 'log'
  | 'debug'
  | 'error'
  | 'info'
  | 'warn'
  | 'group'
  | 'groupEnd'
  | 'time'
  | 'timeEnd'
  | 'trace'
  | 'table';

type LogType = (action: ActionType, ...messages: any) => void;

const log: LogType = (action, ...messages) => {
  if (isDevelopment()) {
    console[action](...messages);
  }
};

const logger = {
  log(...messages: Array<any>): void {
    log('log', ...messages);
  },
  debug(...messages: Array<any>): void {
    log('debug', ...messages);
  },
  error(...messages: Array<any>): void {
    log('error', ...messages);
  },
  info(...messages: Array<any>): void {
    log('info', ...messages);
  },
  warn(...messages: Array<any>): void {
    log('warn', ...messages);
  },
  group(...messages: Array<any>): void {
    log('group', ...messages);
  },
  groupEnd(...messages: Array<any>): void {
    log('groupEnd', ...messages);
  },
  time(...messages: Array<any>): void {
    log('time', ...messages);
  },
  timeEnd(...messages: Array<any>): void {
    log('timeEnd', ...messages);
  },
  trace(...messages: Array<any>): void {
    log('trace', ...messages);
  },
  table(...messages: Array<any>): void {
    log('table', ...messages);
  },
};

export { logger };
