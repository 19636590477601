import { JSX, useCallback } from 'react';

import { Snack } from '../Snack/Snack';
import { SnackProps } from '../Snack/Snack.types';
import { SnackGroup } from './Group.styles';
import { GroupProps } from './Group.types';

const Group: React.FC<GroupProps> = ({ snacks, position = 'bottom-left' }) => {
  const snackMap = useCallback(
    (snack: JSX.IntrinsicAttributes & SnackProps, i: any) => <Snack key={`snack-${position}-${i}`} {...snack} />,
    [snacks]
  );

  return <SnackGroup position={position}>{snacks.map(snackMap)}</SnackGroup>;
};

export { Group };
