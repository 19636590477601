import type { EventParamsType, EventsDictionary } from '../types';

export enum HelpEvents {
  HEADER_MAIN_VIEW = 'header:Main_view',
  HEADER_FAQ_CLICK = 'header:Faq_click',
  MAIN_VIEW = 'helper:Main_view',
  ARTICLE_VIEW = 'helper:Article_view',
  ARTICLE_EXPAND_CLICK = 'helper:Article_expand_click',
  REQUESTING_HELP = 'helper:RequestingHelp_view',
  SEND_HELP_REQUEST_CLICK = 'helper:SendHelpRequest_click',
  CANCEL_HELP_REQUEST_CLICK = 'helper:CancelHelpRequest_click',
}

export type HelpEventsType = typeof HelpEvents;

export type HelpsEventType = keyof HelpEventsType;

export type HelpsEventTypeCollection = HelpsEventType[];

const NEWS_EVENT_KEYS = Object.keys(HelpEvents) as HelpsEventTypeCollection;

export const help: EventsDictionary = NEWS_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = HelpEvents[eventKey];

  return {
    ...collection,
    [eventName]: <HELPSEventParams>(params: EventParamsType<HELPSEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
