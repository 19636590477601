import { Step } from './Step/Step';
import { ActionText, TextWrapper, Title, Wrapper } from './Stepper.styles';
import { StepperProps } from './Stepper.types';

export const Stepper: React.FC<StepperProps> = ({ userAction, title, steps, ...props }) => {
  return (
    <Wrapper {...props}>
      <TextWrapper>
        <ActionText>{userAction}</ActionText>
        <Title variant="heading3">{title}</Title>
      </TextWrapper>

      {steps.map(({ text, variant }) => (
        <Step key={text} text={text} variant={variant} />
      ))}
    </Wrapper>
  );
};
