/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';

function useSessionStorage(key: string, initialValue: any = '') {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key);

      return item || initialValue;
    } catch (err) {
      return initialValue;
    }
  });

  const setItem = (value: string) => {
    setStoredValue(value);
    window.sessionStorage.setItem(key, value);
  };

  const removeItem = () => {
    setStoredValue(initialValue);

    window.sessionStorage.removeItem(key);
  };

  return [storedValue, setItem, removeItem] as const;
}

export { useSessionStorage };
