import { IconButton } from '@ifood/fits-components';
import styled from 'styled-components';

import { colors, width } from '../Snackbar.config';
import { VariantType } from './Snack.types';

export const Container = styled.div<{ variant: VariantType }>`
  width: 100%;
  max-width: ${width}px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  justify-content: space-between;
  background-color: ${({ variant }) => colors[variant].background};

  @media (max-width: 768px) {
    max-width: calc(100% - 48px);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  flex: 1;
  justify-content: space-between;
`;

export const Title = styled.span`
  flex: 1;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const Button = styled(IconButton)`
  padding: 0;
  height: 100%;
  min-height: 24px;
  margin-left: 16px;
`;

export const Link = styled.a`
  color: #1a1a1a;
  text-decoration: underline;
`;
