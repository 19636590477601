import { UserContext, useSnackbar } from '@benefits/common-services';
import { useContext, useEffect, useState } from 'react';

import { BENF_ORDER_ROLLOUT, INVOICED_BOLETO } from '../../store/enabled-features';
import { FeatureContext } from '../../store/FeatureStore';
import * as featureToggles from '../../toggle/featureToggles';
import { ToggleContext } from '../../toggle/ToggleStore';
import { checkChargeBackAvailability } from '../useCheckChargebackAvailability/useCheckChargebackAvailability';

export const useRechargesNXP = () => {
  const { dispatch } = useSnackbar('bottom-right');
  const featureFlagContext = useContext(FeatureContext);
  const featureTogglesContent = useContext(ToggleContext);
  const context = useContext(UserContext);
  const groupId = context?.state?.user?.group?.id;
  const [isChargebackEnabledByGroupIdFromApi, setIsChargebackEnabledByGroupIdFromApi] = useState<boolean>(false);

  useEffect(() => {
    const checkAvailability = async () => {
      try {
        const { exists } = await checkChargeBackAvailability(groupId);
        setIsChargebackEnabledByGroupIdFromApi(exists);
      } catch (error) {
        console.log(error);
      }
    };
    if (groupId) {
      checkAvailability();
    }
  }, [groupId]);

  const isChargebackEnabledByGroupId = featureTogglesContent?.belongsTo(
    featureToggles.ENABLE_CHARGEBACK_IN_RECHARGES_BY_LIST_ID,
    context?.state?.user?.group?.id ?? ''
  );
  const isChargebackDisabledForAll = featureTogglesContent?.isEnable(featureToggles.DISABLE_CHARGEBACK_IN_RECHARGES);
  const isChargebackToggleRevokedForGroupId = featureTogglesContent?.belongsTo(
    featureToggles.REVOKE_CHARGEBACK_ACCESS_BY_GROUPID,
    context?.state?.user?.group?.id ?? ''
  );

  const isChargebackDisabled =
    (isChargebackDisabledForAll && !isChargebackEnabledByGroupId && !isChargebackEnabledByGroupIdFromApi) ||
    isChargebackToggleRevokedForGroupId;

  const RECHARGES_NXP = featureTogglesContent?.isEnable(featureToggles.RECHARGES_NXP);
  const BENF_ORDER_ROLLOUT_ENABLED = featureFlagContext?.isEnable(BENF_ORDER_ROLLOUT);
  const INVOICED_BOLETO_ENABLED = featureFlagContext?.isEnable(INVOICED_BOLETO);
  const isRechargesNXP = BENF_ORDER_ROLLOUT_ENABLED || (RECHARGES_NXP && !INVOICED_BOLETO_ENABLED);

  return {
    isChargebackDisabled,
    isRechargesNXP,
    BENF_ORDER_ROLLOUT_ENABLED,
    INVOICED_BOLETO_ENABLED,
    isLoading: featureTogglesContent?.isFetching || featureFlagContext?.state?.isLoading,
  };
};
