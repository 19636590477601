export * from '@ifood/pomodoro-components';

import { Icon as IfdlIcon } from '@ifood/design-language-icons';
import currentPomodoroTheme from '@ifood/pomodoro-tokens';
import { WEB_THEME as ifoodWebTheme } from '@ifood/web-themes';

export { IfdlIcon };

// The spread positions of the objects are important to override the pomodoroTheme with the fitsTheme.light
// if there are conflicts between the two themes with the same key.
export const pomodoroTheme = {
  ...currentPomodoroTheme,
  colors: {
    ...currentPomodoroTheme.colors,
    //FIXME: This is temporary
    primary: '#B5004C',
    primaryDark: '#8F0340',
    primaryLight: '#DA0565',
    primaryLightest: '#EBEBEB',
    secondary: '#FFFFFF',
    secondaryDark: '#FFA2CC',
    secondaryLight: '#FFC1DD',
    secondaryLightest: '#EBEBEB',
    black: '#141414',
  },
};

export const benefitsWebTheme = {
  ...ifoodWebTheme,
  light: {
    ...ifoodWebTheme.light,
    background: {
      ...ifoodWebTheme.light.background,
      colorBrandPrimary: '#B5004C',
      colorOnBrandPrimary: '#FFFFFF',
      colorOnHoverPrimary: '#FFFFFF',
      colorOnPressedPrimary: '#FFFFFF',
      colorOnSelectedPrimary: '#FFFFFF',
      colorHoverPrimary: '#A70046',
      colorPressedPrimary: '#8F0340',
      colorSelectedPrimary: '#8F0340',
      colorBrandSecondary: '#FAC7CB',
      colorOnBrandSecondary: '#4B070C',
      colorOnHoverSecondary: '#B5004C',
      colorOnPressedSecondary: '#FFA2CC',
      colorOnSelectedSecondary: '#B5004C',
      colorHoverSecondary: '#FFC1DD',
      colorPressedSecondary: '#FFA2CC',
      colorSelectedSecondary: '#FFA2CC',
    },
    outline: {
      ...ifoodWebTheme.light.outline,
      colorBrand: '#8F0340',
    },
    text: {
      ...ifoodWebTheme.light.text,
      colorBrand: '#8F0340',
    },
  },
  dark: {
    ...ifoodWebTheme.dark,
    background: {
      ...ifoodWebTheme.dark.background,
      colorBrandPrimary: '#DA0565',
      colorOnBrandPrimary: '#FFFFFF',
      colorOnHoverPrimary: '#2D0115',
      colorOnPressedPrimary: '#FFFFFF',
      colorOnSelectedPrimary: '#141414',
      colorHoverPrimary: '#FFA2CC',
      colorPressedPrimary: '#FF479B',
      colorSelectedPrimary: '#FF479B',
      colorBrandSecondary: '#41021D',
      colorOnBrandSecondary: '#FF2588',
      colorOnHoverSecondary: '#FF2588',
      colorOnPressedSecondary: '#FF63AA',
      colorOnSelectedSecondary: '#FF63AA',
      colorHoverSecondary: '#69022D',
      colorPressedSecondary: '#69022D',
      colorSelectedSecondary: '#69022D',
    },
    outline: {
      ...ifoodWebTheme.dark.outline,
      colorBrand: '#FF2588',
    },
    text: {
      ...ifoodWebTheme.dark.text,
      colorBrand: '#FF2588',
    },
  },
} as any;
