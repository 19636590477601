/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { HttpMethods, HttpStatusCodes, UnexpectedError, makeRequest } from '@benefits/http-client';

import { BASE_URL, ENDPOINTS } from './endpoints';

type GetFeatureRequestParams = string[];

type GetFeatureResponseBody = {
  features: { [key: string]: string };
};

export const getFeaturesFlags = async (features: GetFeatureRequestParams = []) => {
  const response = await makeRequest<GetFeatureResponseBody>({
    baseURL: BASE_URL,
    method: HttpMethods.GET,
    url: ENDPOINTS.v1.features.get,
    params: {
      features: features.join(','),
    },
  });

  switch (response.status) {
    case HttpStatusCodes.OK:
      return response.body;

    default:
      throw new UnexpectedError(response?.error);
  }
};
