import type { EventParamsType, EventsDictionary } from '../types';

export enum ErrorEvents {
  ERROR_404_NOT_FOUND = 'Error:404_view',
  ERROR_404_NOT_FOUND_CLICK_HOME = 'Error:404_click_home',
  ERROR_404_NOT_FOUND_CLICK_LOGIN = 'Error:404_click_login',
  CRASH = 'Error:CRASH_view',
  CRASH_CLICK_TRY_AGAIN = 'Error:CRASH_click_try_again',
}

export type ErrorEventsType = typeof ErrorEvents;

export type ErrorEventType = keyof ErrorEventsType;

export type ErrorEventTypeCollection = ErrorEventType[];

const Error_EVENT_KEYS = Object.keys(ErrorEvents) as ErrorEventTypeCollection;

export const Error: EventsDictionary = Error_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = ErrorEvents[eventKey];

  return {
    ...collection,
    [eventName]: <NEWSEventParams>(params: EventParamsType<NEWSEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
