import { Unexpected } from './Unexpected/Unexpected';

export interface ErrorBoundaryProps {
  code?: string;
  message?: string;
  title?: string;
  onRetry?: () => void;
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ code, ...props }) => {
  switch (code) {
    default:
      return <Unexpected {...props} />;
  }
};
