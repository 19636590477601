export enum RechargeRoutes {
  INDEX = '/recargas',
  REGISTRATION_TYPE = '/recargas/tipo-de-cadastro',
  PARTICIPANTS = '/recargas/[orderId]/participantes',
  PAYMENT = '/recargas/[orderId]/pagamento',
  SCHEDULE = '/recargas/[orderId]/agendar',
  CONFIRM = '/recargas/[orderId]/confirmar',
  LEGACY_INDEX = '/v3/recharges',
  // new routes to create recharge
  SELECT_OPTION = '/recargas/criar-recarga/selecionar-opcao',
  SELECT_BENEFFITS = '/recargas/criar-recarga/selecao-de-beneficios',
  ADD_PARTICIPANTS = '/recargas/criar-recarga/[orderId]/adicionar-participantes',
  INCLUDE_PARTICIPANT_VALUES = '/recargas/criar-recarga/[orderId]/incluir-valores',
  PAYMENT_SCHEDULE_CREATE = '/recargas/criar-recarga/[orderId]/pagamento-e-datas',
  RESUME_CONFIRMATION = '/recargas/criar-recarga/[orderId]/resumo-confirmacao',
  RECHARGE_SUCCESS = '/recargas/criar-recarga/[orderId]/sucesso',
  RECHARGE_DETAIL = '/recargas/detalhes/[orderId]',
}
