import { Loading as LoadingComponentDefault } from '@benefits/components';
import { logger } from '@benefits/tracking';
import dynamic from 'next/dynamic';
import React from 'react';

import { ErrorBoundary as ErrorBoundaryDefault } from './ErrorBoundary';

type PeersType =
  | 'employee'
  | 'auth'
  | 'license'
  | 'recharge'
  | 'card'
  | 'report'
  | 'admin'
  | 'ifood_card'
  | 'company'
  | 'dashboard'
  | 'financial'
  | 'notification'
  | 'ticket'
  | 'marketplace';

export interface GetFederatedModuleProps {
  remote: PeersType;
  component: string;
  errorBoundary?: React.ComponentType<{ error?: Error }>;
  loadingComponent?: React.VoidFunctionComponent;
}

const getFederatedModule = ({
  remote,
  component,
  errorBoundary: ErrorBoundary = ErrorBoundaryDefault,
  loadingComponent: LoadingComponent = LoadingComponentDefault,
}: GetFederatedModuleProps): React.ComponentType<any> =>
  dynamic(
    () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      const remoteRepository: any = window[remote as keyof Window];

      if (remoteRepository) {
        return remoteRepository
          .get(`./${component}`)
          .then((factory: () => any) => factory())
          .catch((error: Error) => console.log(error));
      }

      logger.error('Failed to render federated module');
      return Promise.resolve(() => <ErrorBoundary />);
    },
    {
      ssr: false,
      loading: () => <LoadingComponent />,
    }
  );

export { getFederatedModule };
