import { UserContext } from '@benefits/common-services';
import { ToggleContext } from '@benefits/features';
import { useContext } from 'react';

export const useMarketplaceHide = () => {
  const context = useContext(UserContext);
  const groupId = context?.state?.user?.group?.id;
  const featureTogglesContent = useContext(ToggleContext);
  const enabled = featureTogglesContent?.belongsTo('MARKETPLACE_HIDE', groupId ?? '');
  const requestSuccess = featureTogglesContent?.isSuccess;
  return requestSuccess && enabled;
};
