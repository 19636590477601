import type { EventParamsType, EventsDictionary } from '../types';

export enum MenuEvents {
  VIEW = 'Menu:View',
  VIEW_CLICK = 'Menu:Click',
}

export type MenuEventsType = typeof MenuEvents;

export type MenuEventType = keyof MenuEventsType;

export type MenuEventTypeCollection = MenuEventType[];

const Menu_EVENT_KEYS = Object.keys(MenuEvents) as MenuEventTypeCollection;

export const Menu: EventsDictionary = Menu_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = MenuEvents[eventKey];

  return {
    ...collection,
    [eventName]: <MenuEventParams>(params: EventParamsType<MenuEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
