import { UserContext, useSessionStorage } from '@benefits/common-services';
import * as SentryLogger from '@benefits/common-services';
import { useQuery } from '@benefits/http-client';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';

import { getProducts } from '../product/getProducts';
import { Products } from '../product/productModel';

export type ProductContextType = {
  products: Products;
  refetch: () => void;
};

const ProductContext = createContext<ProductContextType>({
  products: [],
  // eslint-disable-next-line
  refetch: () => {},
});
ProductContext.displayName = 'ProductContext';

const ProductStore: React.FC<PropsWithChildren> = (props) => {
  const [productsStored, saveProducts] = useSessionStorage('_ife_products', undefined);
  const userContext = useContext(UserContext);
  const hasGroup = useMemo(() => !!userContext?.state?.user?.group, [userContext]);

  const products = useMemo(() => {
    try {
      return productsStored && productsStored.trim() !== '' ? JSON.parse(productsStored) : [];
    } catch (error) {
      SentryLogger.sendMessage({
        message: `Erro ao analisar JSON: ${error as string}`,
        exception: error,
        type: 'error',
      });

      return [];
    }
  }, [productsStored]);

  const { refetch: refetchProducts } = useQuery<Products>('products-active', getProducts, {
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      saveProducts(JSON.stringify(data));
    },
  });

  useEffect(() => {
    if (userContext?.state?.user) {
      refetchProducts();
    }
  }, [userContext?.state?.user?.group]);

  useEffect(() => {
    if (!hasGroup) {
      clearProducts();
    }
  }, [userContext?.state]);

  const clearProducts = () => {
    saveProducts('');
  };

  const payload = useMemo(() => {
    return {
      refetch: refetchProducts,
      clear: clearProducts,
    };
  }, [refetchProducts]);

  return (
    <ProductContext.Provider
      value={{
        ...payload,
        products,
      }}
      {...props}
    />
  );
};

export { ProductContext, ProductStore };
