import { EventParamsType, EventsDictionary } from '../types';

export enum CompanyEvents {
  COMPANY_BENEFITS_PERMISSION_VIEW = 'Companies:Benefits_permission_View',
  COMPANY_BENEFITS_PERMISSION_MODAL_VIEW = 'Companies:Benefits_permission_modal_View',
  COMPANY_BENEFITS_PERMISSION_MODAL_CLICK = 'Companies:Benefits_permission_modal_click',
  COMPANY_BENEFITS_PERMISSION_CLICK = 'Companies:Benefits_permission_click',
  CompaniesView = 'Companies:CompaniesView',
  CompaniesActionClick = 'Companies:CompaniesActionClick',
  CompaniesRegistrationDataView = 'Companies:CompaniesRegistrationDataView',
  CompaniesRegistrationDataSaveClick = 'Companies:CompaniesRegistrationDataSaveClick',
  CompaniesRegistrationDataBackClick = 'Companies:CompaniesRegistrationDataBackClick',
  CompaniesRegistrationDataConfirmationClick = 'Companies:CompaniesRegistrationDataConfirmationClick',
  CompaniesRegistrationDataCancelSaveClick = 'Companies:CompaniesRegistrationDataCancelSaveClick',
  CompaniesRegistrationDataCancelClick = 'CompaniesRegistrationDataCancelClick',
}

export type CompanyEventsType = typeof CompanyEvents;

export type CompanyEventType = keyof CompanyEventsType;

export type CompanyEventTypeCollection = CompanyEventType[];

const CompanyEventKeys = Object.keys(CompanyEvents) as CompanyEventTypeCollection;

export const company: EventsDictionary = CompanyEventKeys.reduce((collection, eventKey) => {
  const eventName = CompanyEvents[eventKey];

  return {
    ...collection,
    [eventName]: <CompanyEventParams>(params: EventParamsType<CompanyEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
