export const CARD_EMPLOYEE_DELIVERY_ENABLED = 'CARD_EMPLOYEE_DELIVERY_ENABLED';
export const CARD_ENABLED = 'CARD_ENABLED';
export const DASHBOARD_FINANCIAL = 'DASHBOARD_FINANCIAL';
export const EMPLOYEE_OPTIONAL_EMAIL = 'EMPLOYEE_OPTIONAL_EMAIL';
export const EMAIL_OR_PHONE_REQUIRED = 'EMAIL_OR_PHONE_REQUIRED';
export const EXTERNAL_INTEGRATIONS = 'EXTERNAL_INTEGRATIONS';
export const INVOICED_BOLETO = 'INVOICED_BOLETO';
export const NEW_MEAL_POLICY = 'NEW_MEAL_POLICY';
export const NEW_USER_MANAGEMENT = 'NEW_USER_MANAGEMENT';
export const RECHARGE_INTEGRATION = 'RECHARGE_INTEGRATION';
export const REISSUE_CARD_BATCH_PLATFORM = 'REISSUE_CARD_BATCH_PLATFORM';
export const CARD_BATCH_PLATFORM = 'CARD_BATCH_PLATFORM';
export const EMPLOYEE_REGISTRATION_V2 = 'EMPLOYEE_REGISTRATION_V2';
export const CORP_V2 = 'CORP_V2';
export const NEW_ADMIN = 'NEW_ADMIN';
export const IFOOD_CARD = 'IFOOD_CARD';
export const BENF_ORDER_ROLLOUT = 'BENF_ORDER_ROLLOUT';
export const BENF_ROLLOUT_POSTPAID = 'BENF_ROLLOUT_POSTPAID';
export const NEW_COMPANY_EXPERIENCE = 'NEW_COMPANY_EXPERIENCE';
export const BENF_HOME_ROLLOUT = 'BENF_HOME_ROLLOUT';
export const NEW_DASHBOARD_EXPERIENCE = 'NEW_DASHBOARD_EXPERIENCE';
export const NEW_REPORT_EMPLOYEE_EXPERIENCE = 'NEW_REPORT_EMPLOYEE_EXPERIENCE';
export const CARD_QUOTA_ENABLED = 'CARD_QUOTA_ENABLED';

export const ENABLED_FEATURES = [
  BENF_ORDER_ROLLOUT,
  CARD_EMPLOYEE_DELIVERY_ENABLED,
  CARD_ENABLED,
  DASHBOARD_FINANCIAL,
  EMPLOYEE_OPTIONAL_EMAIL,
  EMAIL_OR_PHONE_REQUIRED,
  EXTERNAL_INTEGRATIONS,
  INVOICED_BOLETO,
  NEW_MEAL_POLICY,
  NEW_USER_MANAGEMENT,
  RECHARGE_INTEGRATION,
  REISSUE_CARD_BATCH_PLATFORM,
  CARD_BATCH_PLATFORM,
  EMPLOYEE_REGISTRATION_V2,
  CORP_V2,
  NEW_ADMIN,
  NEW_COMPANY_EXPERIENCE,
  BENF_HOME_ROLLOUT,
  NEW_REPORT_EMPLOYEE_EXPERIENCE,
  BENF_ROLLOUT_POSTPAID,
  CARD_QUOTA_ENABLED,
];
