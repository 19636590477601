import React from 'react';

import { Input, Label } from './Radio.styles';
import { RadioRef } from './Radio.types';

interface RadioProps extends React.HTMLAttributes<HTMLInputElement> {
  value: string;
  label: string;
  name: string;
}

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({ label, onChange, ...props }, ref: RadioRef) => {
  return (
    <label>
      <Input role="radio" type="radio" onChange={onChange} {...props} ref={ref} />
      <Label component="span">{label}</Label>
    </label>
  );
});

export { Radio };
