import { NEWS_LOTTIE } from './NewsLottie.enum';

export interface NewsLottieProps {
  lottie: NEWS_LOTTIE;
}

const NewsLottie: React.FC<NewsLottieProps> = ({ lottie }) => {
  switch (lottie) {
    default:
      return <></>;
  }
};

export { NewsLottie };
