import React from 'react';

import { Radio } from '../Radio';
import { RadioRef } from '../Radio.types';
import { RadioGroups } from './RadioGroup.styles';
import { RadioGroupOptionsType } from './RadioGroup.types';

interface RadioGroupProps extends React.HTMLAttributes<HTMLInputElement> {
  options: RadioGroupOptionsType[];
  name: string;
}

const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(({ options, name, ...props }, ref: RadioRef) => {
  return (
    <RadioGroups role="radiogroup">
      {options.map(({ value, label }) => (
        <Radio key={value} label={label} name={name} {...props} ref={ref} value={value} />
      ))}
    </RadioGroups>
  );
});

export { RadioGroup };
