import { BFF_URL } from '@benefits/auth-services/src/services/endpoints';
import { HttpMethods, HttpStatusCodes, UnexpectedError, makeRequest } from '@benefits/http-client';

export type CheckChargeBackAvailabilityResponse = {
  exists: boolean;
};

export const API = '/recharges';
export const BASE_URL = `${BFF_URL}${API}/v1`;

const checkChargeBackAvailability = async (groupId?: string) => {
  const response = await makeRequest<CheckChargeBackAvailabilityResponse>({
    baseURL: BASE_URL,
    method: HttpMethods.GET,
    url: `/chargeback/${groupId}/availability`,
  });

  switch (response.status) {
    case HttpStatusCodes.OK:
      return response.body;

    default:
      throw new UnexpectedError(response?.error);
  }
};

export { checkChargeBackAvailability };
