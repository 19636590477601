export enum AuthRoutes {
  LOGIN = '/login',
  RESET_PASSWORD = '/esqueci-minha-senha',
  NEW_PASSWORD = 'criar-nova-senha',
  LINK_EXPIRED = 'link-expirado',
  REGISTRATION_2FA = '/2fa/cadastro',
  REGISTRATION_PHONE_2FA = '/2fa/cadastro/celular',
  REGISTRATION_DOWNLOAD_APP_2FA = '/2fa/cadastro/baixar-app',
  REGISTRATION_CONFIGURE_APP_2FA = '/2fa/cadastro/configurar-app',
  REGISTRATION_VERIFICATION_CODE_2FA = '/2fa/cadastro/codigo-de-verificacao',
  REGISTRATION_SUCCESS_2FA = '/2fa/cadastro/sucesso',
  AUTHENTICATION_FACTORS_2FA = '/2fa/validacao/fatores-de-autenticacao',
  VALIDATION_VERIFICATION_CODE_2FA = '/2fa/validacao/codigo-de-verificacao',
}
