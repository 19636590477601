import { EventParamsType, EventsDictionary } from '../types';

export enum MarketplaceEvents {
  MarketplaceProductDetailsModalView = 'marketplace:ProductDetails_Modal_view',
  MarketplaceProductDetailsClick = 'marketplace:ProductDetails_click',
  MarketplaceBannerClick = 'marketplace:Banner_click',
  // activation
  MarketplaceProductActivateClick = 'marketplace:ProductActivate_click',
  MarketplaceProductDetailsModalActivateClick = 'marketplace:ProductDetails_Modal_Activate_click',
  MarketplaceProductDetailsModalActivateConfirmationClick = 'marketplace:ProductDetails_Modal_Activate_Confirmation_click',
  MarketplaceProductDetailsModalTeCClick = 'marketplace:ProductDetails_Modal_TeC_click',
  MarketplaceProductActivationModalView = 'marketplace:ProductActivation_Modal_view',
  MarketplaceProductActivationSuccessView = 'marketplace:ProductActivation_Success_view',
  MarketplaceProductActivationModalTeCClick = 'marketplace:ProductActivation_Modal_TeC_click',
  MarketplaceProductActivationModalCancelClick = 'marketplace:ProductActivation_Modal_Cancel_click',
  MarketplaceProductActivationSuccessNextstepClick = 'marketplace:ProductActivation_Success_Nextstep_click',
  MarketplaceProductActivationModalCallback = 'marketplace:ProductActivation_Modal_Callback',
  // contract
  MarketplaceColabWhatsappClick = 'marketplace:colab_help_whatsapp_click',
  MarketplaceProductContractClick = 'marketplace:ProductContract_click',
  MarketplaceProductContractModalView = 'marketplace:ProductContract_Modal_view',
  MarketplaceProductContractSuccessView = 'marketplace:ProductContract_Success_view',
  MarketplaceProductContractModalCancelClick = 'marketplace:ProductContract_Modal_Cancel_click',
  MarketplaceProductContractSuccessNextstepClick = 'marketplace:ProductContract_Success_Nextstep_click',
  MarketplaceProductContractModalCallback = 'marketplace:ProductContract_Modal_Callback',
  MarketplaceProductDetailsModalContractClick = 'marketplace:ProductDetails_Modal_Contract_click',
  MarketplaceProductDetailsModalContractConfirmationClick = 'marketplace:ProductDetails_Modal_Contract_Confirmation_click',
  MarketplaceProductPlansView = 'marketplace:ProductPlansView',
  MarketplaceProductPaymentMethodView = 'marketplace:ProductPaymentMethodView',
  MarketplaceProductPlanModalTermsView = 'marketplace:ProductPlanModalTermsView',
  MarketplaceProductPlanModalCancelView = 'marketplace:ProductPlanModalCancelView',
  MarketplaceProductPlanModalErrorView = 'marketplace:ProductPlanModalErrorView',
  MarketplaceProductPlanModalErrorTryAgainClick = 'marketplace:ProductPlanModalErrorTryAgainClick',
  MarketplaceProductPlanSuccessView = 'marketplace:ProductPlanSuccessView',
  MarketplaceProductPlanClick = 'marketplace:ProductPlanClick',
  MarketplaceProductPaymentMethodSubmitClick = 'marketplace:ProductPaymentMethodSubmitClick',
  MarketplaceProductPaymentMethodCancelClick = 'marketplace:ProductPaymentMethodCancelClick',
  MarketplaceProductModalTermsDownloadClick = 'marketplace:ProductModalTermsDownloadClick',
  MarketplaceProductModalTermsAcceptClick = 'marketplace:ProductModalTermsAcceptClick',
  MarketplaceProductModalTermsCancelClick = 'marketplace:ProductModalTermsCancelClick',
  MarketplaceProductModalTermsSubmitClick = 'marketplace:ProductModalTermsSubmitClick',
  MarketplaceProductModalCancelCloseClick = 'marketplace:ProductModalCancelCloseClick',
  MarketplaceProductModalCancelConfirmCancelClick = 'marketplace:ProductModalCancelConfirmCancelClick',
  MarketplaceProductModalCancelGoBackTermsClick = 'marketplace:ProductModalCancelGoBackTermsClick',
  // informative
  MarketplaceProductDetailsModalInformativeConfirmationClick = 'marketplace:ProductDetails_Modal_Informative_Confirmation_click',
  MarketplaceProductDetailPageView = 'marketplace:product_detail_page_view',
  MarketplaceProductRatingClick = 'marketplace:product_rating_click',
  MarketplaceProductOpinionSubmitClick = 'marketplace:product_opinion_submit_click',
  MarketplaceAllProductsView = 'marketplace:all_products_view',
  MarketplaceBenefitsProductsView = 'marketplace:benefits_view',
  MarketplaceEmployeesProductsView = 'marketplace:employees_products_view',
  MarketplaceHRProductsView = 'marketplace:hr_products_view',
  MarketplaceActiveProductsView = 'marketplace:active_products_view',
  MarketplaceDashboardProductsClick = 'marketplace:dashboard_products_click',
}

export type MarketplaceEventsType = typeof MarketplaceEvents;

export type MarketplaceEventType = keyof MarketplaceEventsType;

export type MarketplaceEventTypeCollection = MarketplaceEventType[];

const MarketplaceEventKeys = Object.keys(MarketplaceEvents) as MarketplaceEventTypeCollection;

export const marketplace: EventsDictionary = MarketplaceEventKeys.reduce((collection, eventKey) => {
  const eventName = MarketplaceEvents[eventKey];

  return {
    ...collection,
    [eventName]: <MarketplaceEventParams>(params: EventParamsType<MarketplaceEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
