import { EventParamsType, EventsDictionary } from '../types';

export enum LicenseEvents {
  LicensesActionClick = 'License:LicenseActionClick',
  LicenseEditNameView = 'License:LicenseEditNameView',
  LicenseEditNameSaveClick = 'License:LicenseEditNameSaveClick',
  LicenseEditNameBackClick = 'License:LicenseEditNameBackClick',
  LicenseEditNameConfirmationClick = 'License:LicenseEditNameConfirmationClick',
}

export type LicenseEventsType = typeof LicenseEvents;

export type LicenseEventType = keyof LicenseEventsType;

export type LicenseEventTypeCollection = LicenseEventType[];

const LicenseEventKeys = Object.keys(LicenseEvents) as LicenseEventTypeCollection;

export const License: EventsDictionary = LicenseEventKeys.reduce((collection, eventKey) => {
  const eventName = LicenseEvents[eventKey];

  return {
    ...collection,
    [eventName]: <LicenseEventParams>(params: EventParamsType<LicenseEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
