import { FontSizesTypeMap } from './fontSizes.types';

export const fontSizes: FontSizesTypeMap<string> = {
  '3xl': '3rem',
  xxl: '2.5rem',
  xl: '2rem',
  l: '1.5rem',
  ml: '1.25rem',
  m: '1rem',
  s: '0.875rem',
  xs: '0.75rem',
};
